import React from 'react'
import { Link } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection'
import { AdminOptions, ClientDetails, JobDetailsSection } from '../../../styles/SuperAdmin.styled'

export default function AllCreatorsDetails({setViewData}) {
    const { isLoading, document } = useCollection("creators")
    const handleView = data => {
        setViewData(data);
    }
    return (
        <JobDetailsSection>
            <AdminOptions>
                <p style={{ borderBottom: `${"2px solid #006AC2" }`, color: `${ "#006AC2" }` }}>All Creators</p>
                {/* <p onClick={() => setShowApproved(false)} style={{ borderBottom: `${!showApproved ? "2px solid #006AC2" : ""}`, color: `${!showApproved ? "#006AC2" : "#000"}` }} >All JOb</p> */}
                {/* <p onClick={() => setShowApproved(true)} style={{ borderBottom: `${showApproved ? "2px solid #006AC2" : ""}`, color: `${!showApproved ? "#000" : "#006AC2"}` }}>Approved Data</p> */}
            </AdminOptions>

            {
                document && document.map(doc => (
                    <ClientDetails key={doc?.id}>
                        <p>{doc?.name}</p>
                        <p>{doc?.email}</p>
                        <p>{doc?.phone}</p>
                        <p>{doc?.type}</p>
                        <Link to={`/superadmin/creators/${doc?.id}`}><button>Assessment</button></Link>
                        <button onClick = {() => handleView(doc)}>view</button>
                    </ClientDetails>
                ))
            }
            {/* {
                viewForm && <JobPostingForm JobDetails={JobDetails} setJobDetails={setJobDetails} />
            } */}

        </JobDetailsSection>
    )
}
