import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { Button } from '../../../styles/Common.styled'
import { DetailsData } from '../../../styles/SuperAdmin.styled'

export default function SelectedJobDetails({actualCreator}) {
    const { updateDocument } = useFirestore("selectedCreators")
    const handleAcceptDeadline = () => {
        let updatedObj = {
            ...actualCreator,
            deadline: actualCreator?.extendDateApply,
            extendDateApply: null
        }

        updateDocument(actualCreator?.id, updatedObj)
    }

    const handleApproved = () => {
        let updatedObj = {
            ...actualCreator,
            projectApproved: true,
        }

        updateDocument(actualCreator?.id, updatedObj)
    }
    return (
        <DetailsData>
            <p>Project Name:</p>
            <p>{actualCreator?.name}</p>
            <p>Development:</p>
            {actualCreator?.isDevelopmentDone ? <p style={{ color: "green" }}>Complete</p> : <p style={{ color: "red" }}>not Complete</p>}
            <p>Project Testing:</p>
            {actualCreator?.isTestingDone ? <p style={{ color: "green" }}>Complete</p> : <p style={{ color: "red" }}>not Complete</p>}
            <p>Project Progress:</p>
            <p>{actualCreator?.isComplete ? "complete" : "not Complete"}</p>
            {actualCreator?.isComplete ? <p style={{ color: "green" }}>Complete</p> : <p style={{ color: "red" }}>not Complete</p>}
            <p>Project link:</p>
            {actualCreator?.projectLink ? <p style={{ color: "green" }}>{actualCreator?.projectLink}</p> : <p style={{ color: "red" }}>Project not completed Yet</p>}
            <p>Github link:</p>
            {actualCreator?.githubLink ? <p style={{ color: "green" }}>{actualCreator?.githubLink}</p> : <p style={{ color: "red" }}>Project not completed Yet</p>}
            <p>Deadline:</p>
            <p>{actualCreator?.deadline}</p>
            {
                actualCreator?.isComplete && !actualCreator?.projectApproved ? <>
                    <p>Approved:</p>
                    <Button onClick={handleApproved}>Approved</Button>
                </> : <>
                    <p>Approved:</p>
                    <p>Project Approved</p>
                </>
            }


            {/* if apply for exted date then this will be appear */}
            {
                actualCreator?.extendDateApply ? <>
                    <p>Extend Deadline:</p>
                    <p>{actualCreator?.extendDateApply}</p>
                    <Button onClick={handleAcceptDeadline}>Accept Deadline</Button>
                </> : ""
            }

        </DetailsData>
    )
}
