import React from 'react'
import { CompletedProject, ProfileProject, ProfileprojectPhase } from '../../../styles/Profile.styled'
import { FaArrowCircleRight } from "react-icons/fa";
import { useCollection } from '../../../hooks/useCollection';
import { useAuthContext } from '../../../hooks/useAuthContext';

export default function CreatorProject() {
    const { user } = useAuthContext();
    const { document: selectedCreator } = useCollection("selectedCreators");

    let selectCreator = selectedCreator?.filter(creator => creator?.userUID == user?.uid && creator.projectApproved !== true)
    let completeProject = selectedCreator.filter(creator => creator.userUID == user?.uid && creator.projectApproved)


    return (
        // if completed project or running project have then this will be visible
        (completeProject.length || selectCreator.length) ? <ProfileProject>

            {/* if creators done any project with ongshak then those project name will visible in here */}
            {
                completeProject.length ? <CompletedProject>
                    <p>Completed Project with us:</p>
                    <div>
                        {
                            completeProject?.map(project => (
                                <div>
                                    <FaArrowCircleRight />
                                    <p>{project.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </CompletedProject> : ""
            }

            {/* if creators have any running project with ongshak then this project name will visible in here */}
            {
                selectCreator.length ? <>
                    <CompletedProject>
                        <p>Running Project with us:</p>
                        <div>
                            <div>
                                <FaArrowCircleRight />
                                <p>{selectCreator[0]?.name}</p>
                            </div>
                        </div>
                    </CompletedProject>
                    <ProfileprojectPhase>
                        <div>
                            <span>Development:</span>
                            <p style={{color: `${selectCreator[0]?.isDevelopmentDone}` ? "green" : ""}}>Completed</p>
                        </div>
                        <div>
                            <span>Testing:</span>
                            <p>{selectCreator[0]?.isTestingDone ? "Finished" : "Running"}</p>
                        </div>
                        <div>
                            <span>Complete:</span>
                            <p>No</p>
                        </div>
                    </ProfileprojectPhase>
                </> : ""
            }


        </ProfileProject> : ""
    )
}
