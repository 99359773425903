import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';
import Navbar from '../../../organism/shared/Navbar'
import { From } from '../../../styles/Common.styled'
import { JobApplying } from '../../../styles/Jobs.styled'

export default function JobApplyForm() {
  let [error, setError] = useState("");
  let [success, setSuccess] = useState("");
  let [resume, setResume] = useState("");
  let [project, setProject] = useState("");

  const { user } = useAuthContext();
  const { document: allJobs } = useCollection("jobs");
  const { addDocument } = useFirestore("jobApply");
  const { document: creators } = useCollection(
    "creators",
    user?.uid && ["userId", "==", user?.uid]
  );


  const { jobId } = useParams();
  const navigate = useNavigate("");


  const selectingJob = allJobs.filter(job => job?.id === jobId);

  const handleSubmit = e => {
    e.preventDefault();
    if (project === "" || resume === "") {
      setError("Please Fill all the input field");
      setSuccess("");
      return
    }

    const ApplyDocument = {
      fullName: creators[0]?.name,
      phone: creators[0]?.phone,
      email: creators[0]?.email,
      location: creators[0]?.location,
      resume: resume,
      github: creators[0]?.github,
      linkedin: creators[0]?.linkedin,
      project: project,
      userUID: user?.uid
    }

    addDocument({ ...ApplyDocument, job: selectingJob[0]?.jobName });
    setSuccess("Your application send to the Ongshak");
    setError("")
    setTimeout(() => {
      navigate("/jobs");
    }, 3000);
  }

  return (
    <>
      <Navbar />
      <JobApplying>
        <h2>Let us know your Information</h2>
        <From onSubmit={handleSubmit}>
          <input type="text" value={creators[0]?.name} />
          <input type="text" placeholder='Phone' value={creators[0]?.phone} />
          <input type="text" placeholder='Location' value={creators[0]?.location} />
          <input type="text" placeholder='Resume Link' value={resume} onChange={e => setResume(e.target.value)} />
          <input type="text" placeholder='Github profile' value={creators[0]?.github} />
          <input type="text" placeholder='Linkedin profile' value={creators[0]?.linkedin} />
          <input type="text" placeholder='Best project link' value={project} onChange={e => setProject(e.target.value)} />
          <input type="submit" value="Submit" />
        </From>
        <p style={{ color: "red", fontSize: "1.8rem" }}>{error}</p>
        <p style={{ color: "green", fontSize: "1.8rem" }}>{success}</p>
      </JobApplying>
    </>
  )
}
