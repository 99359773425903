import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import SIgnUpForm from './Components/SIgnUpForm'

export default function SignUp() {

  return (
    <>
      <Navbar />
      <SIgnUpForm />
    </>
  )
}
