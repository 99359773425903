import { useState, useEffect } from "react";
import { auth, db } from "../firebase.config";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification  } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import {  doc, setDoc } from "firebase/firestore";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email, password, displayName) => {
        setError(null);
        setIsPending(true);
        try {
            // Create the user
            let res = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(res.user);

            if (!res) {
                throw new Error("Could not complete signup");
            }

            // Update the user profile
            await updateProfile(res.user, { displayName });

            // add document to the collection with specific id
            await setDoc(doc(db, "users", res.user.uid), {
                online: true,
                displayName
            })

            // Dispatch login action
            dispatch({ type: "LOGIN", payload: res.user });

            if(!isCancelled) {
                setError("");
                setIsPending(false);
            }

        } catch (err) {
            if (!isCancelled) {
                setError(err.message);
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { signup, error, isPending };
};