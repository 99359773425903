import styled from "styled-components";

export const Navigation = styled.nav`
    background: #fff;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    /* margin-bottom: 1rem; */
    
    img {
        width: 30rem;
    }
`
export const NavContainer = styled.div`
    max-width: 120rem;
    margin: 0 auto;
    padding: 2rem 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        flex-direction: column;
    }

`

export const NavDetails = styled.div`
    display: flex;
    align-items: center;

    ul {
        display: flex;
        li {
            list-style: none;
            margin: 1rem 2rem;
            cursor: pointer;
            font-size: 1.8rem;
        }
    }

    svg {
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        flex-direction: column;
    }

`