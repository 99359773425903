import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../hooks/useFirestore"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useCollection } from '../../../hooks/useCollection';
import { From } from '../../../styles/Common.styled'
import { NoExpForm } from '../../../styles/CreatorsForm.styled'

export default function NoExpDetailsForm() {
  let [alert, setAlerts] = useState("")
  let [creatorTech, setCreatorTech] = useState(false);
  
  const { user } = useAuthContext()
  const [noExpDetails, setNoExpDetails] = useState({
    name: user.displayName,
    phone: "",
    location: "",
    github: "",
    linkedin: "",
    technology: ""
  });

  const { addDocument, updateDocument } = useFirestore("creators")
  const { document: allCreators } = useCollection("creators")

  const navigate = useNavigate("")

  const creatorsExisting = allCreators?.filter(creator => creator.userId === user?.uid)

  const handleSubmit = e => {
    e.preventDefault();

    if (noExpDetails.name == "" || noExpDetails.phone == "" || noExpDetails.location == "" || noExpDetails.github == "" || noExpDetails.linkedin == "" || noExpDetails.technology == "" || noExpDetails.technology == "others") {
      console.log(noExpDetails)
      setAlerts("please fill the input field")
      return
    }


    // if creator already exist in the database then will update the creators collection
    if (creatorsExisting.length) {
      let updatedObj = {
        ...creatorsExisting[0],
        type: "type_D"
      }
      updateDocument(creatorsExisting[0]?.id, updatedObj)
      navigate("/success")
      resetCachedData()
      setAlerts("")

      // if no creator in the database then will add the creators collection
    } else {
      const Details = {
        ...noExpDetails,
        type: "type_D",
        email: user?.email,
        userId: user?.uid
      }
      addDocument(Details)
      navigate("/success")
      resetCachedData()
      setAlerts("")
    }
  }

  const resetCachedData = () => {
    setNoExpDetails({
      name: "",
      phone: "",
      location: "",
      github: "",
      linkedin: "",
      technology: ""
    })
  }


  // while creators click on others for technology then this useEffect will run
  useEffect(() => {
    if(noExpDetails.technology === "others") {
      setCreatorTech(true);
    }
  }, [noExpDetails.technology])

  console.log("noExpDetails",noExpDetails);

  return (
    <NoExpForm>
      <h2>Let us know your Information</h2>
      <From onSubmit={handleSubmit}>
        <input type="text" placeholder='Full Name' value={noExpDetails.name} onChange={e => setNoExpDetails({ ...noExpDetails, name: e.target.value })}/>
        <input type="number" placeholder='Phone' value={noExpDetails.phone} onChange={e => setNoExpDetails({ ...noExpDetails, phone: e.target.value })} />
        <input type="text" placeholder='Location' value={noExpDetails.location} onChange={e => setNoExpDetails({ ...noExpDetails, location: e.target.value })} />
        <input type="text" placeholder='Github Link' value={noExpDetails.github} onChange={e => setNoExpDetails({ ...noExpDetails, github: e.target.value })} />
        <input type="text" placeholder='Linkedin' value={noExpDetails.linkedin} onChange={e => setNoExpDetails({ ...noExpDetails, linkedin: e.target.value })} />
        {
          creatorTech && <input type="text" placeholder='Write your Preffered Stack' onChange={e => setNoExpDetails({ ...noExpDetails, technology: e.target.value })} />
        }
        {
          !creatorTech ? <select onChange={e => setNoExpDetails({ ...noExpDetails, technology: e.target.value })}>
            <option value="">Chose Your Stack</option>
            <option value="MERN">MERN</option>
            <option value="react">React</option>
            <option value="flutter">Flutter</option>
            <option value="others">others</option>
          </select> : ""
        }

        <p style={{ color: "red", fontSize: "2rem" }}>{alert}</p>
        <input type="submit" value="Submit" />
      </From>
    </NoExpForm>
  )
}
