import styled from "styled-components";

export const Heading = styled.h2`
    color: red;
    font-size: 2rem;
`

export const Container = styled.section`
    max-width: 120rem;
    margin: 0 auto;
    padding: 3rem;
`

export const FullScreenMessage = styled.section`
    min-height: calc(100vh - 12vh);
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ bg }) => bg || (({ theme }) => theme.color.bg)};
    font-size: 2.8rem;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.8rem;
        text-align: justify;
    }
`

export const Button = styled.button`
    border: none;
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
    background-color: ${({ bg }) => bg || (({ theme }) => theme.color.primary)};
    color: ${({ color }) => color || "#000"};
    font-weight: ${({ weight }) => weight || 600};
    border-radius: ${({ radius }) => radius || ""};
    cursor:pointer ;   
`

export const Input = styled.input`
    width: 50%;
    padding: 1rem;
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};
    margin-bottom: 2rem;
`



export const GridTwo = styled.div`
    display: Grid;
    width: ${({ width }) => width || ""};
    gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns:  ${({ column }) => column || "repeat(2, 1fr)"};
    align-items: center;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
        text-align:center;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
    }
`


export const GridFour = styled.div`
    display: Grid;
    gap: ${({gap}) => gap || "2rem"};
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        grid-template-columns: 1fr;
        gap: 1rem;
        text-align: center;
    }
`


export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ justify }) => justify || "center"};
    gap: ${({ gap }) => gap || "2rem"};
    & > div {
        flex: 1;
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
        flex-direction: column;
        /* text-align: center; */
    }
`

export const From = styled.form`
   
    h2 {
        font-size: 3.2rem;
        margin-bottom: 4rem;
    }
    input {
        width: 100%;
        border: none;
        padding: 1rem 0;
        border-bottom: 2px solid ${({ theme }) => theme.color.primary};
        margin: 1rem 0;
        font-size: 1.8rem;
        transition: .1s;

        :focus {
            outline: none;
            padding: 1rem .5rem;
            background-color: rgba(249, 141, 18, .1);
        }
    }

    input[type=submit] {
        margin-top: 3rem;
        background: ${({ theme }) => theme.color.primary} ;
        font-size: 2.2rem;
        font-weight: 600;
    }

    select {
        width: 100%;
        border: none;
        padding: 1rem 0;
        border-bottom: 2px solid ${({ theme }) => theme.color.primary};
        margin: 1rem 0;
        font-size: 1.8rem;

        :focus {
            outline: none;
            padding: 1rem .5rem;
            background-color: rgba(249, 141, 18, .1);
        }
    }
`


export const SuccesSection = styled.section`
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 108, 10, 1);

    h2 {
        padding: 0 3rem;
        font-size: 1.8rem;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
`

export const GreetingsSection = styled.div`
    position: fixed;
    bottom: 5%;
    right: 2%;
    border: 2px solid #F98D12 ;
    background: rgba(249, 141, 18, 1);
    padding: 2rem;
    max-width: 30rem;

    font-size: 1.6rem;
    color: #fff;

    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
`

export const GreetSection = styled.div`
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        background: #F8FAFA;
        width: 70%;
        /* height: 40%; */
        border-radius: .8rem;
        margin: 0 auto;
        padding: 3rem;
        font-size: 1.8rem;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            margin-bottom: 2rem;
            border-radius: .8rem .8rem 0 0;
        }

        span {
            font-size: 2rem;
            font-weight: 700;
            color: #F98D12;
        }
        p {
            margin-bottom: 1rem;
        }
    }
`