import React, { useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { MainSuperAdmin } from '../../../styles/SuperAdmin.styled'
import SelectedCreatorData from './SelectedCreatorData'
import SelectedJobDetails from './SelectedJobDetails'

export default function SelectedCreatorsContent() {
    let [jobId, setJobId] = useState("");
    const { document: selectorsData } = useCollection("selectedCreators");
    const actualCreator = selectorsData.filter(data => data?.id === jobId);

    console.log("jobId", jobId)
    return (
        <MainSuperAdmin>
            <SelectedCreatorData setJobId={setJobId} />
            {/* <ViewCreatorsData viewData={viewData} /> */}
            {
                actualCreator.length ? <div>
                    <p>Details</p>
                    <SelectedJobDetails actualCreator={actualCreator[0]} />
                </div> : ""
            }

        </MainSuperAdmin>
    )
}
