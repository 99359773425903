import React, { useState } from 'react'
import { MainSuperAdmin, MainData, DetailsData, DetailsHeading, Details } from '../../../styles/SuperAdmin.styled'
import SuperAdminData from './SuperAdminData'
import SuperAdminDetails from './SuperAdminDetails'

export default function SuperAdminContent({ setView, view,setUserData }) {
  let [viewDetails, setViewDetails] = useState("")
  return (
    <MainSuperAdmin>
      <SuperAdminData setView={setView} setUserData={setUserData}  setViewDetails={setViewDetails} />
      <Details>
        <DetailsHeading>Details</DetailsHeading>
        <SuperAdminDetails view={view} viewDetails={viewDetails} />
      </Details>
    </MainSuperAdmin>
  )
}