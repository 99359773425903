import React from 'react'
import { AskingLeft, AskingQuestions, AskingRight, ExpSection } from '../../../styles/CreatorsForm.styled'
import formGif from "../../../assets/images/form.gif"
import logo from "../../../assets/images/ongshaklogoss.png"
import { Button } from '../../../styles/Common.styled'

export default function AskingExp({ setExperience, setShowForm, setShowExperienceDetails }) {
    const handleNoUploader = () => {
        setExperience(false);
        setShowForm(true);
    }

    const handleYesUploader = () => {
        setExperience(true);
        setShowExperienceDetails(true);
    }
    return (
        <ExpSection>
            <AskingQuestions>
                <AskingLeft bg={formGif}>
                    <h2>Change the Quality of your life</h2>
                    <p>n publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                </AskingLeft>
                <AskingRight>
                    <div>
                        <img src={logo} alt="ongshak" />
                        <p>n publishing and graphic design, Lorem ipsum is a text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        <h2 style={{ textAlign: "center" }}>Have you ever work before?</h2>
                        <Button onClick={handleNoUploader}>NO</Button>
                        <Button onClick={handleYesUploader}>YES</Button>
                    </div>
                </AskingRight>
            </AskingQuestions>
        </ExpSection>
    )
}
