import React, { useState } from 'react'
import { AdminOptions, ClientDetails } from '../../../styles/SuperAdmin.styled'
import { useCollection } from '../../../hooks/useCollection'
import webIcon from "../../../assets/images/webIcon.png"
import ApprovedDetails from './ApprovedDetails'
import { useNavigate } from 'react-router-dom'

export default function AllClientStory({ setView, setViewDetails,  setUserData }) {
  const { isLoading, document } = useCollection("clientStory")
  const [showApproved, setShowApproved] = useState(false)

  const navigate = useNavigate("")

  const handleView = data => {
    setViewDetails(data)
  }

  const handleAnalyze = (data) => {
    setUserData(data)
    navigate("/superAdmin/analyze")
  }
  
  const handleChat = (data) => {
    setUserData(data)
    navigate("/superAdmin/chat")
  }

  return (
    <>
      <AdminOptions>
        <p onClick={() => setShowApproved(false)} style={{borderBottom: `${!showApproved ? "2px solid #006AC2" : ""}`, color : `${!showApproved ? "#006AC2" : "#000"}`}} >ClientDetails</p>
        <p onClick={() => setShowApproved(true)} style={{borderBottom: `${showApproved ? "2px solid #006AC2" : ""}`, color : `${!showApproved ? "#000" : "#006AC2"}`}}>Approved Data</p>
      </AdminOptions>
      {
        !showApproved && document && document.map(doc => (
          <ClientDetails key={doc?.id}>
            <img src={webIcon} alt="web Icon" />
            <p>{doc?.projectName}</p>
            <p>{doc?.projectType}</p>
            <p>{doc?.deadline}</p>
            <button style={{backgroundColor: `${doc?.analyze ? "Green": ""}`}} onClick={() => handleAnalyze(doc)}>Analyze</button>
            <button onClick={() => handleView(doc)}>View</button>
            <button onClick={() => handleChat(doc)}>Chat</button>
          </ClientDetails>
        ))
      }
      {
        showApproved && <ApprovedDetails setUserData={setUserData} />
      }

    </>
  )
}
