import React, { useState, useEffect } from 'react'
import { From } from '../../../styles/Common.styled'
import { AskingLeft, AskingQuestions, AskingRight, NoExpForm } from '../../../styles/CreatorsForm.styled'
import formGif from "../../../assets/images/form.gif"
import { useFirestore } from '../../../hooks/useFirestore';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useNavigate } from "react-router-dom"
import { useCollection } from '../../../hooks/useCollection';

import emailjs from 'emailjs-com';

export default function CreatorsInformation() {
    
    let [creatorTech, setCreatorTech] = useState(false);
    let [alert, setAlerts] = useState("");

    const { user } = useAuthContext()
    let [creatorDetails, setCreatorDetails] = useState({
        name: user.displayName,
        phone: "",
        location: "",
        github: "",
        linkedin: "",
        technology: ""
    });

    
    const { addDocument, updateDocument } = useFirestore("creators");
    const { document: allCreators } = useCollection("creators");
    const navigate = useNavigate("")
    

    const creatorsExisting = allCreators?.filter(creator => creator.userId === user?.uid)

    const handleSubmit = e => {
        e.preventDefault();

        if (creatorDetails.name == "" || creatorDetails.phone == "" || creatorDetails.location == "" || creatorDetails.github == "" || creatorDetails.linkedin == "" || creatorDetails.technology == "" || creatorDetails.technology == "others") {
            console.log(creatorDetails)
            setAlerts("please fill the input field")
            return
        }

        // if creator already exist in the database then will update the creators collection
        if (creatorsExisting.length) {
            let updatedObj = {
                ...creatorsExisting[0],
                type: "assessment",
            }
            updateDocument(creatorsExisting[0]?.id, updatedObj);
            navigate("/success");
            setAlerts("");
        } else {
            // if no creator in the database then will add the creators collection
            const details = {
                ...creatorDetails,
                type: "assessment",
                email: user?.email,
                userId: user?.uid
            }
            addDocument(details);
            navigate("/");
            setAlerts("");
        }


        // sending email to the user through email js
        const templateParams = {
            Name: user.displayName,
            email: user?.email,
            phone: creatorDetails.phone,
            location: creatorDetails.location,
            github: creatorDetails.github,
            linkedin: creatorDetails.linkedin,
            technology: creatorDetails.technology,
            user: user?.email,
        };

        emailjs.send('service_6dr168w', 'template_0gal3yi', templateParams, 'user_VmFia1Ter6HW78JbH0WiK')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
    }

    // while creators click on others for technology then this useEffect will run
    useEffect(() => {
        if (creatorDetails.technology === "others") {
            setCreatorTech(true);
        }
    }, [creatorDetails.technology])

    return (
        <AskingQuestions>
            <AskingLeft bg={formGif}>
                <h2>Change the Quality of your life</h2>
                <p>n publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </AskingLeft>
            <AskingRight>
                <NoExpForm>
                    <h2>Let us know your Information</h2>
                    <From onSubmit={handleSubmit}>
                        <input type="text" placeholder='Full Name' value={user.displayName} />
                        <input type="number" placeholder='Phone' value={creatorDetails.phone} onChange={e => setCreatorDetails({ ...creatorDetails, phone: e.target.value })} />
                        <input type="text" placeholder='Location' value={creatorDetails.location} onChange={e => setCreatorDetails({ ...creatorDetails, location: e.target.value })} />
                        <input type="text" placeholder='Github Link' value={creatorDetails.github} onChange={e => setCreatorDetails({ ...creatorDetails, github: e.target.value })} />
                        <input type="text" placeholder='Linkedin' value={creatorDetails.linkedin} onChange={e => setCreatorDetails({ ...creatorDetails, linkedin: e.target.value })} />
                        {
                            creatorTech && <input type="text" placeholder='Write your Preffered Stack' onChange={e => setCreatorDetails({ ...creatorDetails, technology: e.target.value })} />
                        }
                        {
                            !creatorTech ? <select onChange={e => setCreatorDetails({ ...creatorDetails, technology: e.target.value })}>
                                <option value="">Chose Your Stack</option>
                                <option value="MERN">MERN</option>
                                <option value="REACT_NATIVE">React Native</option>
                                <option value="FLUTTER">Flutter</option>
                                <option value="others">others</option>
                            </select> : ""
                        }

                        <p style={{ color: "red", fontSize: "2rem", padding: "0" }}>{alert}</p>
                        <input type="submit" value="Submit" />
                    </From>
                </NoExpForm>
            </AskingRight>
        </AskingQuestions>
    )
}
