import React from 'react'
import { Container, GridTwo } from '../../../styles/Common.styled'
import { NoExpDetail, NoExpSection } from '../../../styles/CreatorsForm.styled'
import NoExpDetailsForm from './NoExpDetailsForm'

export default function NoExpDetails() {
    return (
        <NoExpSection>
            <Container>
                <GridTwo>
                    <NoExpDetail>
                        <h2>Who do not have experience , we will utilize them.....</h2>
                        <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available..In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
                    </NoExpDetail>
                    <NoExpDetailsForm />
                </GridTwo>
            </Container>
        </NoExpSection>
    )
}
