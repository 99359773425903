import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import AllResources from './Components/AllResources'

export default function Resources() {
    return (
        <>
            <Navbar />
            <AllResources />
        </>
    )
}
