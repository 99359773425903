import styled from "styled-components";

export const ResourceSection = styled.section`
    background:  ${({ theme }) => theme.color.bg};
    min-height: 87vh;
`

export const ResourceContainer = styled.div`
    display: grid;
    grid-template-columns: 35rem 1fr;
    gap: 2rem;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
    }
`

export const ResourceSidebar = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    align-self: start;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;

    }
`

export const ResourceContent = styled.div`
    padding: 0 2rem;
`

export const ResourceList = styled.div`
    margin: 2rem 0;
    display: flex;
    gap: 4rem;
    align-items: center;

    div {
        min-width: 5rem;
        min-height: 5rem;
        border: 2px solid #000;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
        border-radius: .4rem
    }

    p {
        color: rgba(116, 132, 153, 1);
        font-size: 1.8rem;
        font-weight: 500;
    }
`

export const ResourceCard = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;

    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 200px;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
        text-align:center;
    }
`
export const ResourceVideoDetails = styled.div`
    color: #000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }
    
    p {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
`

export const ResourceBar = styled.div`
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: .4rem;
    margin-bottom: 2rem;
    padding: 2rem;

    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    p {
        font-size: 1.8rem;
        background: #f1f1f1;
        padding: 1rem 2rem;
        border-radius: .4rem;
        cursor:pointer;
        transition: all .3s;

        :hover {
            background: #ddd;
        }
    }

    @media (max-width: 500px) {
        flex-direction: column;
    }
`
