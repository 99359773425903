import React from 'react'
import { MainData } from '../../../styles/SuperAdmin.styled'
import AllClientStory from './AllClientStory'
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'

export default function SuperAdminData({ setView , setViewDetails, setUserData}) {
  return (
    <MainData>
      <SuperAdminCards />
      <AllClientStory setView={setView}  setUserData={setUserData} setViewDetails={setViewDetails} />
    </MainData>
  )
}
