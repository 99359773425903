import React from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import SuperAdminCreatorsContent from './Component/SuperAdminCreatorsContent'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdminCreators() {
    return (
        <SuperAdminSection>
            <SuperAdminSidebar />
            <SuperAdminCreatorsContent />
        </SuperAdminSection>
    )
}
