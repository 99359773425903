import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import Alljobs from './Components/Alljobs'

export default function Jobs() {
    return (
        <>
            <Navbar />
            <Alljobs />
        </>
    )
}
