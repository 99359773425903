import React from 'react'
import { MainSuperAdmin } from '../../../styles/SuperAdmin.styled'
import SuperAdminJobData from './SuperAdminJobData'

export default function SuperAdminJobContent() {
    return (
        <MainSuperAdmin>
            <SuperAdminJobData />
            <h2>hello</h2>
        </MainSuperAdmin>
    )
}
