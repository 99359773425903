import React, {useState} from 'react'
import { AdminOptions, ClientDetails, JobDetailsSection } from '../../../styles/SuperAdmin.styled'

// import { Textarea } from '../../../styles/Form.styled';
import { JobForm } from '../../../styles/SuperAdmin.styled';

import { useFirestore } from '../../../hooks/useFirestore';

export default function SelectCreators({selectedCreator, job}) {
    console.log("userUid", selectedCreator?.userUID);
    let [jobDetail, setJobDetail] = useState({
        name: "",
        details: "",
        deadline: "",
        ammount: "",
        figma: "",
        userUID: selectedCreator?.userUID
    })

    const {updateDocument } = useFirestore("jobApply");
    const { addDocument } = useFirestore("selectedCreators");
    const { updateDocument:updateJOb } = useFirestore("jobs");

    const handleSubmit = e => {
        e.preventDefault();

        const obj = {
            ...selectedCreator,
            selected: true,
            rejected: false,
        }

        // updateDocument(id, obj);
        updateDocument(selectedCreator?.id, obj);
        addDocument(jobDetail)

        const jobObj = {
            ...job,
            creatorsNeed: false
        }
        updateJOb(job?.id, jobObj);

        setJobDetail({
            name: "",
            details: "",
            deadline: "",
            ammount: "",
            figma: ""
        })
    }
  return (
        <JobForm onSubmit={handleSubmit}>
            <label>
                <p>Project Name:</p>
                <input type="text" placeholder='eg. Ongshak MVP' value = {jobDetail.name} onChange = {e => setJobDetail({...jobDetail, name: e.target.value})} />
            </label>
            <label>
                <p>Project Details:</p>
                <textarea bg="#fff" placeholder='Details'  value = {jobDetail.details} onChange = {e => setJobDetail({...jobDetail, details: e.target.value})} />
            </label>

            <label>
                <p>Figma Link:</p>
                <input type="text" placeholder='eg. https://figma.com/' value = {jobDetail.figma} onChange = {e => setJobDetail({...jobDetail, figma: e.target.value})} />
            </label>

            <label>
                <p>Project Deadline:</p>
                <input type="date"  value = {jobDetail.deadline} onChange = {e => setJobDetail({...jobDetail, deadline: e.target.value})}/>
            </label>

            <label>
                <p>Project Ammount</p>
                <input type="number" value = {jobDetail.ammount} onChange = {e => setJobDetail({...jobDetail, ammount: e.target.value})} />
            </label>

            <input type="submit" value="Post JOb" />
        </JobForm>
  )
}
