import React from 'react'
import { Container, Flex, GridTwo } from '../../../styles/Common.styled'

import clockIcon from "../../../assets/images/clockicon.png"
import { ExpectationDetails, ExpectationDetailsSection, ExpectationHeading, ExpectationSection } from '../../../styles/Home.styled'

export default function Expectation() {
    return (
        <ExpectationSection>
            <Container>
                <GridTwo>
                    <div>
                        <ExpectationHeading>What can I expect during the test and Project?</ExpectationHeading>
                    </div>
                    <ExpectationDetailsSection>
                        <Flex>
                            <article>
                                <img src={clockIcon} alt="clockIcon" />
                            </article>
                            <div>
                                <h2>Deadline</h2>
                                <p>If you fail to complete the Assessment, no harm done. you have still opportunity to work with us by learning from our resources.</p>
                            </div>
                        </Flex>
                        <Flex>
                            <article>
                                <img src={clockIcon} alt="clockIcon" />
                            </article>
                            <ExpectationDetails>
                                <h2>Deadline</h2>
                                <p>If you fail to complete the Assessment, no harm done. you have still opportunity to work with us by learning from our resources.</p>
                            </ExpectationDetails>
                        </Flex>
                    </ExpectationDetailsSection>
                </GridTwo>
            </Container>
        </ExpectationSection>
    )
}
