import React, { useState } from 'react'

import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuthContext } from './useAuthContext';
import { auth } from '../firebase.config';
import { useNavigate } from "react-router-dom"
import { useFirestore } from './useFirestore';
import { useCollection } from './useCollection';

export const useSignInWithGoogle = () => {
    let [error, setError] = useState(false)
    const { dispatch } = useAuthContext()
    const navigate = useNavigate("")
    const { addDocument } = useFirestore("users")
    const {document:usersCollection, isLoading} = useCollection("users")


    const handleSignInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        setError(false)
        signInWithPopup(auth, provider)
            .then(result => {
                let isUserhave = usersCollection?.filter(user => user?.email === result?.user?.email)
                console.log(isUserhave)
                if(isUserhave.length === 0) {
                    addDocument({ email: result?.user?.email, type: "Dev"});
                }
                dispatch({ type: "LOGIN", payload: { ...result.user, type: "Dev"} })
                navigate("/creators-form")
            })
            .catch(err => {
                setError(err.message)
            })
    }

    const handleSignOutWithGoogle = () => {
        signOut(auth)
            .then(() => {
                dispatch({ type: "LOGOUT" })
            })
            .catch(err => {
                console.log("Your email or password was incorrect")
            })
    }

    return { error, handleSignInWithGoogle, handleSignOutWithGoogle }
}
