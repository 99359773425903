import React from 'react'
import { Container, FullScreenMessage } from '../../../styles/Common.styled'
import { JobCard, JobSection } from '../../../styles/Jobs.styled'
import { FaArrowRight } from 'react-icons/fa';
import { Link } from "react-router-dom"
import { useCollection } from '../../../hooks/useCollection';


export default function Alljobs() {
    const { document: Jobs } = useCollection("jobs");

    const JobAvailable = Jobs.filter(job => job.creatorsNeed === true);

    console.log("Jobs", Jobs);
    return (
        <JobSection>
            <Container>
                {
                    JobAvailable.length ? <h2>Job Available</h2> : <FullScreenMessage>
                        <Container>
                            <h2>No Job Available at this momment</h2>
                        </Container>
                    </FullScreenMessage>
                }

                {
                    JobAvailable.map(job => (
                        <Link to={`/jobs/${job.id}`} style={{ textDecoration: "none", color: "currentcolor" }}>
                            <JobCard>
                                <div>
                                    <h2>{job.jobName}</h2>
                                    <p>Vaccancy: <span>1</span></p>
                                </div>
                                <div>
                                    <FaArrowRight />
                                </div>
                            </JobCard>
                        </Link>
                    ))
                }
            </Container>
        </JobSection>
    )
}
