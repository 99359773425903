import './App.css';
import { ThemeProvider } from "styled-components";
import GlobalStyles from './styles/Global.styled';
import Home from './views/Home/Home';
import CreatorsForm from './views/CreatorsForm/CreatorsForm';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Jobs from './views/Jobs/Jobs';
import JobDetails from './views/Jobs/JobDetails';
import JobApplyForm from './views/Jobs/Components/JobApplyForm';
import Resources from './views/Resources/Resources';
import Projects from './views/Projects/Projects';
import SignUp from './views/SignUp/SignUp';
import SuperAdmin from './views/SuperAdmin/SuperAdmin';
import SuperAdminJobsPage from './views/SuperAdmin/SuperAdminJobsPage';
import SuperAdminAppliedJOb from './views/SuperAdmin/SuperAdminAppliedJOb';
import SuperAdminCreators from './views/SuperAdmin/SuperAdminCreators';
import SuperAdminIndividualCreator from './views/SuperAdmin/SuperAdminIndividualCreator';
import Success from './organism/Success/Success';
import Assessment from './views/Assessment/Assessment';
import { useAuthContext } from './hooks/useAuthContext';
import Login from './views/Login/Login';
import SuperAdminSelectedCreators from './views/SuperAdmin/SuperAdminSelectedCreators';
import Profile from './views/Profile/Profile';

const Theme = {
  color: {
    white: "#fff",
    black: "#1F1D1D",
    bg: "#F8FAFA",
    primary: "#F98D12"
  },
  mobile: "880px",
  smallMobile: "580px"
}

function App() {
  const { authIsReady, user } = useAuthContext();
  return (
    authIsReady && (
      <BrowserRouter>
        <ThemeProvider theme={Theme}>
          <GlobalStyles />

          <Routes>

            {/* page routing */}
            <Route path="/" element={<Home />} />
            {
              user && <Route path="/creators-form" element={<CreatorsForm />} />
            }
            {
              !user && <Route path="/creators-form" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/jobs" element={<Jobs />} />
            }
            {
              !user && <Route path="/jobs" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/jobs/:jobId" element={<JobDetails />} />
            }
            {
              !user && <Route path="/jobs/:jobId" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/jobs/:jobId/apply" element={<JobApplyForm />} />
            }
            {
              !user && <Route path="/jobs/:jobId/apply" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/resources" element={<Resources />} />
            }
            {
              !user && <Route path="/resources" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/projects" element={<Projects />} />
            }
            {
              !user && <Route path="/projects" element={<Navigate replace to="/login" />} />
            }


            {
              user && <Route path="/success" element={<Success />} />
            }
            {
              !user && <Route path="/success" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/assessment" element={<Assessment />} />
            }
            {
              !user && <Route path="/assessment" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/profile" element={<Profile />} />
            }
            {
              !user && <Route path="/profile" element={<Navigate replace to="/login" />} />
            }

            {
              !user && <Route path="/sign-up" element={<SignUp />} />
            }
            {
              user && <Route path="/sign-up" element={<Navigate replace to="/" />} />
            }
            {
              !user && <Route path="/login" element={<Login />} />
            }
            {
              user && <Route path="/login" element={<Navigate replace to="/" />} />
            }


            {/* Super Admin Router */}
            {
              user && <Route path="/superAdmin" element={<SuperAdmin />} />
            }
            {
              !user && <Route path="/superAdmin" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/superAdmin/jobs" element={<SuperAdminJobsPage />} />
            }
            {
              !user && <Route path="/superAdmin/jobs" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/superAdmin/jobs/:jobID" element={<SuperAdminAppliedJOb />} />
            }
            {
              !user && <Route path="/superAdmin/jobs/:jobID" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/superAdmin/creators" element={<SuperAdminCreators />} />
            }
            {
              !user && <Route path="/superAdmin/creators" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/superAdmin/creators/:creatorID" element={<SuperAdminIndividualCreator />} />
            }
            {
              !user && <Route path="/superAdmin/creators/:creatorID" element={<Navigate replace to="/login" />} />
            }

            {
              user && <Route path="/superAdmin/selectedCreators" element={<SuperAdminSelectedCreators />} />
            }
            {
              !user && <Route path="/superAdmin/selectedCreators" element={<Navigate replace to="/login" />} />
            }
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    )
  );
}

export default App;

