import React from 'react'
import { MainSuperAdmin } from '../../../styles/SuperAdmin.styled'
import CreatorAssessmentForm from './CreatorAssessmentForm'

export default function CreatorAssessment() {
    return (
        <MainSuperAdmin>
            <CreatorAssessmentForm />
            <h2>hello</h2>
        </MainSuperAdmin>
    )
}
