import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { Phase } from '../../../styles/ProjectDetails.styled'

export default function ProjectPhase({ selectCreator, setError }) {
  const { updateDocument } = useFirestore("selectedCreators")

    const handleDevelopment = () => {
        let obj = {
            ...selectCreator,
            isDevelopmentDone: true
        }

        updateDocument(selectCreator?.id, obj)
    }
    const handleTesting = () => {
        let obj = {
            ...selectCreator,
            isDevelopmentDone: true,
            isTestingDone: true
        }

        updateDocument(selectCreator?.id, obj)
    }
    
    const handleComplete = () => {
        setError("Please Submit Your Necessary Link, so, that the project will be finished");
    }

  return (
    <Phase>
        <button disabled={selectCreator?.isDevelopmentDone} style={{ backgroundColor: selectCreator?.isDevelopmentDone ? "#ddd" : "" }} onClick={handleDevelopment}>Development</button>
        <button disabled={selectCreator?.isTestingDone} style={{ backgroundColor: selectCreator?.isTestingDone ? "#ddd" : "" }} onClick={handleTesting}>Testing</button>
        <button disabled={selectCreator?.isComplete} style={{ backgroundColor: selectCreator?.isComplete ? "#ddd" : "" }} onClick={handleComplete}>Complete</button>
    </Phase>
  )
}
