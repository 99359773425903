import styled from "styled-components";

export const FooterLogo = styled.div`
    h2 {
        font-size: 4.2rem;
    }
`

export const FooterLinks = styled.div`
    ul {
        list-style-type: none;
        li {
            font-size: 1.8rem;
            margin: 1.3rem 0;
            cursor: pointer;

            &:first-child {
                font-weight: 700;
            }
        }
    }
`