import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import Navbar from '../../organism/shared/Navbar'
import AssessmentDetails from './Components/AssessmentDetails'
import AssessmentGreeting from './Components/AssessmentGreeting'

export default function Assessment() {
  const { authIsReady, user } = useAuthContext()
  const { document, isLoading } = useCollection(
    "creators"
  )

  const creators = document.filter(creator => creator?.userId == user?.uid);

  const giveAssessment = creators?.filter(creator => creator?.type === "give_assessment" && creator?.userId === user?.uid)
  return (
    <>
      <Navbar />
      {
        giveAssessment.length ? <AssessmentDetails /> : <AssessmentGreeting />
      }

    </>
  )
}
