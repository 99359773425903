import React from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useCollection } from '../../../hooks/useCollection';
import { Container, Flex, GreetSection } from '../../../styles/Common.styled'
import welcomeImg from "../../../assets/images/welcome.jpg"

export default function AssessmentGreeting() {
    const { user } = useAuthContext();

    const { document: creators } = useCollection(
        "creators",
        user?.uid && ["userId", "==", user?.uid]
    )

    console.log("assessment creator", creators)
    // Filterring type of creator from all Creators
    const typeAssessment = creators?.filter(creator => creator?.type === "assessment");
    const typeSubmitAssessment = creators?.filter(creator => creator?.type === "submit_assessment");
    return (
        <Container>
            <GreetSection>
                {
                    typeSubmitAssessment.length ? <div>
                        <img src={welcomeImg} alt="welcome Img" />
                        <p>Hey, <span>{creators[0]?.name}</span></p>
                        <p>You have submitted our assessment.we will give you a feedback within a very short time. Thanks</p>
                    </div> : ""
                }

                {
                    typeAssessment.length ? <div>
                        <img src={welcomeImg} alt="welcome Img" />
                        <p>Hey, <span>{creators[0]?.name}</span></p>
                        <p>Thank you so much for your interest to work with us. we will assign you a task within 24 hours.</p>
                    </div> : ""
                }

            </GreetSection>
        </Container>
    )
}
