import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore';
import { Button } from '../../../styles/Common.styled';
import { ProfileForms, ProfileInfo } from '../../../styles/Profile.styled'

export default function CreatorInfo({ setCreatorDetail, creator, setAlert, alert, creatorDetail }) {

    const { updateDocument } = useFirestore("creators");

    const handleProfileSave = () => {
        setAlert("");
        if (!creatorDetail) {
            setAlert("please update your profile");
            return
        }
        if (creatorDetail) {
            updateDocument(creatorDetail.id, creatorDetail);
            setCreatorDetail("");
            setAlert("profile updated Successfully");
        }
    }

    return (
        <>

            <ProfileInfo>
                <p>Update your profile</p>
                <ProfileForms>
                    <div>
                        <p>FullName:</p>
                        <input type="text" defaultValue={creator?.name} onChange={e => setCreatorDetail({ ...creator, name: e.target.value })} />
                    </div>
                    <div>
                        <p>Location:</p>
                        <input type="text" defaultValue={creator?.location} onChange={e => setCreatorDetail({ ...creator, location: e.target.value })} />
                    </div>
                    <div>
                        <p>Phone:</p>
                        <input type="text" defaultValue={creator?.phone} onChange={e => setCreatorDetail({ ...creator, phone: e.target.value })} />
                    </div>
                    <div>
                        <p>Github:</p>
                        <input type="text" defaultValue={creator?.github} onChange={e => setCreatorDetail({ ...creator, github: e.target.value })} />
                    </div>
                    <div>
                        <p>linkedin:</p>
                        <input type="text" defaultValue={creator?.linkedin} onChange={e => setCreatorDetail({ ...creator, linkedin: e.target.value })} />
                    </div>

                    <div>
                        <p>Technology:</p>
                        <p>{creator?.technology}</p>
                    </div>
                    <div>
                        <p>Email:</p>
                        <p>{creator?.email}</p>
                    </div>
                    {/* <div>
                        <p>type of Creator:</p>
                        <p>{creator?.type}</p>
                    </div> */}
                    <Button style={creatorDetail ? { background: "#F98D12" } : { background: "#ddd" }} onClick={handleProfileSave}>Save</Button>
                </ProfileForms>
            </ProfileInfo>
            {
                alert && <p style={{ color: "red", fontSize: "1.8rem", fontWeight: "600" }}>{alert}</p>
            }
        </>
    )
}
