import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { ClientDetails } from '../../../styles/SuperAdmin.styled'
import webIcon from "../../../assets/images/webIcon.png"
import { useFirestore } from '../../../hooks/useFirestore'

// import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'

export default function ApprovedDetails({ setUserData }) {
    const { document } = useCollection("clientStory")
    const { updateDocument } = useFirestore("clientStory")

    const navigate = useNavigate("")

    const filteredData = document.filter(data => data.approvedDate === true)

    // Handle Development phase
    const handleDevelopment = (id, data) => {
        let obj = {
            ...data,
            isDevelopmentDone: true,
            phase: 4
        }

        updateDocument(id, obj)

        // sending email to the user through email js
        // const templateParams = {
        //     firstName: data.firstName,
        //     user: data.user
        // };

        // emailjs.send('service_6dr168w', 'template_21fgh17', templateParams, 'user_VmFia1Ter6HW78JbH0WiK')
        //     .then((response) => {
        //         console.log('SUCCESS!', response.status, response.text);
        //     }, (err) => {
        //         console.log('FAILED...', err);
        //     });
        alert("Development done")
    }

    // handle Testing
    const handleTesting = (id, data) => {
        let obj = {
            ...data,
            isTestingDone: true
        }

        updateDocument(id, obj)

        // sending email to the user through email js
        // const anothertemplateParams = {
        //     firstName: data.firstName,
        //     user: data.user
        // };

        // emailjs.send('service_6dr168w', 'template_21fgh17', anothertemplateParams, 'user_VmFia1Ter6HW78JbH0WiK')
        //     .then((response) => {
        //         console.log('SUCCESS!', response.status, response.text);
        //     }, (err) => {
        //         console.log('FAILED...', err);
        //     });
        alert("Testing Done")
    }

    const handleChat = (data) =>{
        setUserData(data)
        navigate("/superAdmin/chat")
    }

    return (
        filteredData && filteredData.map(doc => (
            <ClientDetails key={doc?.id}>
                <img src={webIcon} alt="web Icon" />
                <p>{doc?.projectName}</p>
                <p>{doc?.projectType}</p>
                <p>{doc?.deadline}</p>
                <button style={{backgroundColor: `${doc?.isDevelopmentDone ? "Green": ""}`}} onClick={() => handleDevelopment(doc?.id, doc)}>Development Phase</button>
                <button style={{backgroundColor: `${doc?.isTestingDone ? "Green": ""}`}} onClick={() => handleTesting(doc?.id, doc)}>Testing Phase</button>
                <button onClick={() => handleChat(doc)}>Chat</button>
            </ClientDetails>
        ))
    )
}
