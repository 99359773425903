import React, { useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { MainSuperAdmin, Details, DetailsHeading } from '../../../styles/SuperAdmin.styled'
import AppliedJobData from './AppliedJobData'
import AppliedJobView from './AppliedJobView'

export default function AppliedJob() {
  return (
    <MainSuperAdmin>
      <AppliedJobData  />
      {
       <Details>
          <DetailsHeading>Details</DetailsHeading>
          {/* <AppliedJobView actualCreator={actualCreator[0]} /> */}
        </Details>
      }

    </MainSuperAdmin>
  )
}
