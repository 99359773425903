import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import Greetings from '../../organism/Greetings/Greetings'
import Footer from '../../organism/shared/Footer'
import Navbar from '../../organism/shared/Navbar'
import Expectation from './Components/Expectation'
import Hero from './Components/Hero'
import RiskFree from './Components/RiskFree'

export default function Home() {
    const { user } = useAuthContext()
    return (
        <>
            <Navbar />
            <Hero />
            <RiskFree />
            <Expectation />
            {/* <NetworkButton /> */}
            <Footer />
            {
                user && <Greetings />
            }
        </>
    )
}
