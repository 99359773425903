import React from 'react'
import { DetailsData } from '../../../styles/SuperAdmin.styled'

export default function SuperAdminDetails({ viewDetails }) {
  return (
    viewDetails && <DetailsData>
      <p>FirstName</p>
      <p>{viewDetails.firstName}</p>
      <p>lastName</p>
      <p>{viewDetails.lastName}</p>
      <p>Phone</p>
      <p>{viewDetails.phone}</p>
      <p>Email</p>
      <p>{viewDetails.email}</p>
      <p>Referrence Web/App</p>
      <p>{viewDetails.reference}</p>
      <p>Project Name</p>
      <p>{viewDetails.projectName}</p>
      <p>Project Type</p>
      <p>{viewDetails.projectType.map(type => <span>{type}, </span>)}</p>
      <p>Project requirement</p>
      <p>{viewDetails.projectRequirement}</p>
      <p>Deadline</p>
      <p>{viewDetails.deadline}</p>
      <p>Comment</p>
      <p>{viewDetails.eta}</p>
    </DetailsData>
  )
}
