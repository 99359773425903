import React from 'react'
import { useParams } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import Navbar from '../../organism/shared/Navbar'
import IndividualJob from './Components/IndividualJob'

export default function JobDetails() {

    const { jobId } = useParams();
    const { document: Jobs } = useCollection("jobs");
    const selectingJob = Jobs.filter(job => job?.id === jobId);
    
    return (
        <>
            <Navbar />
            <IndividualJob selectingJob={selectingJob[0]} />
        </>
    )
}
