import React from 'react'
import { Details, DetailsHeading } from '../../../styles/SuperAdmin.styled'
import CreatorsDetails from './CreatorsDetails'

export default function ViewCreatorsData({viewData}) {
  return (
      <Details>
        <DetailsHeading>Details</DetailsHeading>
        <CreatorsDetails viewData = {viewData} />
      </Details>
  )
}
