import React, { useState, useEffect } from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import { Button, Container, Input } from '../../../styles/Common.styled'
import { ProjectCompleteSection, ProjectDetailSection, ProjectIngrediants } from '../../../styles/ProjectDetails.styled'
import ProjectPhase from "./ProjectPhase"
import ProjectSubmittingForm from "./ProjectSubmittingForm"

import { FaArrowCircleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'

export default function ProjectDetails() {
    const [error, setError] = useState("")

    // ============= temporary Commenting start ===================

    // const [extendDateError, setExtendDateError] = useState("")
    // const [extendDateField, setExtendDateField] = useState(false);
    // const [extendDate, setExtendDate] = useState("");

    // const { updateDocument } = useFirestore("selectedCreators");

    // ============= temporary Commenting end ===================


    const { user } = useAuthContext("users");
    const { document: selectedCreator } = useCollection("selectedCreators");

    
    let selectCreator = selectedCreator?.filter(creator => creator?.userUID == user?.uid && creator.projectApproved !== true)
    let completeProject = selectedCreator.filter(creator => creator.userUID == user?.uid && creator.projectApproved)
    console.log("selectedCreator", selectedCreator)
    console.log("selectedCreator", selectCreator)

    // Date Functionality in two way
    
    // let deadline = new Date(2022, 3, 27).toLocaleString("en-US", {timeZone: "America/New_York"});
// deadline = new Date(deadline);
// let now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
// now = new Date(now)

// console.log(deadline);
// console.log(now);
// console.log( deadline - now);


// let deadline = new Date(2022, 4, 25); // at 12:00 AM
// let now = new Date(); // current date and time
// let milliseconds = deadline.getTime() - now.getTime();
// milliseconds = 10000; // set to 10 seconds for testing purposes

// console.log(deadline.getTime())
// console.log(now.getTime())


// setInterval(() => {
// console.log( deadline.getTime() - now.getTime())
// }, 1000)





    // ============= temporary Commenting start ===================

    // apply to date extend to the authority
    // const handleApply = () => {
    //     if (extendDate === "") {
    //         setExtendDateError("please fill out the input field")
    //         return
    //     }

    //     const selectorObj = {
    //         ...selectCreator[0],
    //         extendDateApply: extendDate
    //     }

    //     updateDocument(selectCreator[0]?.id, selectorObj);
    //     setExtendDateField(false);

    // }

    // ============= temporary Commenting end ===================

    return (
        <Container>
            {/* completed project showing */}
            {
                completeProject.length ? <ProjectCompleteSection>
                    <p>Your Completed Project with us:</p>
                    <div>
                        {
                            completeProject.map(project => (
                                <p><FaArrowCircleRight /> {project.name}</p>
                            ))
                        }
                    </div>
                </ProjectCompleteSection> : ""
            }

            {/* If project running then this will apprear */}
            {
                selectCreator.length ? <ProjectDetailSection>
                    <h2>{selectCreator[0]?.name}</h2>
                    <p>{selectCreator[0]?.details}</p>

                    <ProjectIngrediants>
                        <div>
                            <p>Ammount: </p>
                            <p>{selectCreator[0]?.ammount}BDT</p>
                        </div>
                        <div>
                            <p>Deadline: </p>
                            <p>{selectCreator[0]?.deadline}</p>
                        </div>
                        <div>
                            <a href={selectCreator[0]?.figma} target="_blank"><p>Design Link</p></a>
                        </div>
                    </ProjectIngrediants>

                    {/* apply extend date for deadline */}
                    {/* {
                        !extendDateField && <Button onClick={() => setExtendDateField(true)}>Extend Date</Button>
                    }
                    {
                        extendDateField && <div>
                            <Input type="date" value={extendDate} onChange={e => setExtendDate(e.target.value)} bg="#f1f1f1" color="#000" />
                            <p style={{ color: "red" }}>{extendDateError}</p>
                            <Button onClick={handleApply}>apply</Button>
                        </div>
                    } */}

                    {
                        selectCreator[0]?.extendDateApply ? <p style={{ margin: "2rem 0", color: "green" }}>your application for Extend the deadline submitted successfully</p> : ""
                    }


                    <p style={{ marginTop: "2rem" }}><span>Project Phase: </span></p>
                    <ProjectPhase selectCreator={selectCreator[0]} setError={setError} />

                    {/* if project is complete then the messeage will appear */}
                    {
                        selectCreator[0]?.isComplete && selectCreator[0]?.githubLink && selectCreator[0]?.projectLink && <p style={{ color: "green", fontSize: "2.2rem", marginTop: "2rem" }}>Your project submission completed</p>
                    }

                    {/* if project is not complete then the submiting form will appear */}
                    {
                        !selectCreator[0]?.isComplete && <ProjectSubmittingForm setError={setError} selectCreator={selectCreator[0]} />
                    }
                    {
                        error && <p style={{ color: "red", fontSize: "2.2rem", marginTop: "2rem" }}>{error}</p>
                    }
                </ProjectDetailSection> : <p style={{textAlign: "center", fontSize: "1.8rem"}}>You can apply for project in our <Link to="/jobs" style={{color: "blue"}}>job</Link> portal</p>
            }

        </Container>
    )
}
