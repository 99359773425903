import React, { useState } from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { Button } from '../../../styles/Common.styled'
// import { Input, NextButton, Select } from '../../../styles/Form.styled'
import { DetailsData } from '../../../styles/SuperAdmin.styled'

export default function CreatorsDetails({ viewData }) {
    const [selectType, setSelectType] = useState("")
    const [error, setError] = useState("")

    const { updateDocument } = useFirestore("creators")

    const handleTypeUpdate = () => {
        if (selectType === "") {
            setError("please select type first");
            return
        }

        if (selectType === "type_C" || selectType === "type_D") {
            const creatorObj = {
                ...viewData,
                type: selectType,
                resourceDone: false
            }

            updateDocument(viewData.id, creatorObj);
        } else {
            const creatorObj = {
                ...viewData,
                type: selectType
            }
            updateDocument(viewData.id, creatorObj);
        }
    }

    return (
        viewData && <DetailsData>
            <p>Name</p>
            <p>{viewData?.name}</p>
            <p>Email</p>
            <p>{viewData?.email}</p>
            <p>Phone</p>
            <p>{viewData?.phone}</p>
            <p>location</p>
            <p>{viewData?.location}</p>
            <p>Technology</p>
            <p>{viewData?.technology}</p>
            <p>Creators Type</p>
            <div>
                <span style={{ fontSize: "1.8rem" }}>{viewData?.type}</span>
                <select onChange={e => setSelectType(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="type_A">Type A</option>
                    <option value="type_B">Type B</option>
                    <option value="type_C">Type C</option>
                    <option value="type_D">Type D</option>
                    <option value="type_F">Type F</option>
                </select>
                {/* <Input type="text" onChange={e => setSelectType(e.target.vaue)} placeholder='eg. type_A' /> */}
                <Button onClick={handleTypeUpdate}>change Type</Button>
                {
                    error && <p style={{color: "red"}}>{error}</p>
                }
            </div>
            <p>assessment Github</p>
            <p><a href={viewData?.assessmentGithub}>{viewData?.assessmentGithub}</a></p>
            <p>assessment Project</p>
            <p><a href={viewData?.assessmentProject}>{viewData?.assessmentProject}</a></p>
        </DetailsData>
    )
}
