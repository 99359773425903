import React from 'react'
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'
import { MainData } from '../../../styles/SuperAdmin.styled'
import AllCreatorsDetails from './AllCreatorsDetails'

export default function SuperAdminCreatorsData({ setViewData }) {
    return (
        <MainData>
            <SuperAdminCards />
            <AllCreatorsDetails setViewData={setViewData} />
        </MainData>
    )
}
