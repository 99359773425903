import React from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';
import { GreetingsSection } from '../../styles/Common.styled'

export default function Greetings() {
    const { user } = useAuthContext();

    const { document: creators } = useCollection(
        "creators",
        user?.uid && ["userId", "==", user?.uid]
    )

    // Filterring type of creator from all Creators
    const typeAssessment = creators?.filter(creator => creator?.type === "assessment");
    const typeSubmitAssessment = creators?.filter(creator => creator?.type === "submit_assessment");
    return (
        <>
            {/* {
                typeAssessment.length && <GreetingsSection>Thank you so much for your interest to work with us. we will assign you a task within 24 hours.</GreetingsSection>
            } */}
            {
                // typeSubmitAssessment.length && <GreetingsSection>You have submitted our assessment.we will give you a feedback within a very short time. Thanks</GreetingsSection>
            }
        </>
    )
}
