import React from 'react'
import { NavContainer, NavDetails, Navigation } from '../../styles/Navbar.styled'
import logo from "../../assets/images/ongshaklogoss.png"
import { Button } from '../../styles/Common.styled'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

import { FaRegUserCircle } from 'react-icons/fa';

export default function Navbar() {
    const { authIsReady, user } = useAuthContext()
    const navigate = useNavigate("");

    const { document: selectedCreator } = useCollection(
        "selectedCreators"
    );
    const { document, isLoading } = useCollection(
        "creators"
    )

    const creators = document.filter(creator => creator?.userId == user?.uid);

    console.log("creators from navbar", document)

    // Filterring type of creator from all Creators
    const giveAssessment = creators?.filter(creator => (creator?.type === "give_assessment" || creator?.type === "assessment" || creator?.type === "submit_assessment") && creator?.userId === user?.uid);
    const creatorTypeA = creators?.filter(creator => creator?.type === "type_A");
    const creatorTypeB = creators?.filter(creator => creator?.type === "type_B");
    const creatorTypeC = creators?.filter(creator => creator?.type === "type_C");
    const creatorTypeD = creators?.filter(creator => creator?.type === "type_D");


    let selectCreator = selectedCreator.filter(creator => creator.userUID == user?.uid)
    let CreatorHaveProject = selectCreator.filter(creator => !creator.projectApproved)

    const handleNetwork = () => {
        if (user?.uid) {
            navigate("/creators-form");
        } else {
            navigate("/sign-up");
        }
    }

    return (
        <Navigation>
            <NavContainer>
                <Link to="/">
                    <div>
                        <img src={logo} alt="Ongshak" />
                    </div>
                </Link>
                <NavDetails>
                    {
                        authIsReady && <>

                            {
                                !isLoading && creators.length ? <Link to="/profile" style={{ color: 'currentcolor' }}>
                                    <FaRegUserCircle />
                                </Link> : <Button onClick={handleNetwork} radius="100px">Creators Network</Button>
                            }

                            <ul>
                                {
                                    giveAssessment.length ? <Link to="/assessment" style={{ textDecoration: "none", color: "currentcolor" }}>
                                        <li>Assessment</li>
                                    </Link> : ""
                                }


                                {
                                    selectCreator.length ? <Link to="/projects" style={{ textDecoration: "none", color: "currentcolor" }}>
                                        <li>Projects</li>
                                    </Link> : ""
                                }

                                {
                                    (creatorTypeA.length && !CreatorHaveProject.length) || (creatorTypeB.length && !CreatorHaveProject.length) ? <Link to="/jobs" style={{ textDecoration: "none", color: "currentcolor" }}>
                                        <li>Jobs</li>
                                    </Link> : ""
                                }
                                {
                                    creatorTypeC.length || creatorTypeD.length ? <Link to="/resources" style={{ textDecoration: "none", color: "currentcolor" }}>
                                        <li>Resources</li>
                                    </Link> : ""
                                }

                            </ul>
                        </>
                    }
                </NavDetails>
            </NavContainer>
        </Navigation>
    )
}
