import React from 'react'
import animation from "../../assets/images/loading.gif"
import { SuccesSection } from '../../styles/Common.styled'

export default function Progress() {
    return (
        <SuccesSection>
            <div>
                <img src={animation} alt="animation" />
            </div>

            <h2>You have successfully signed in. We are redirecting you to the form</h2>
        </SuccesSection>
    )
}
