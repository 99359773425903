import React, { useState } from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { useCollection } from '../../../hooks/useCollection'
import { AdminOptions, JobDetailsSection, JobForm, ClientDetails } from '../../../styles/SuperAdmin.styled'
import JobPostingForm from './JobPostingForm';
import { Link } from 'react-router-dom'

export default function AllJobDetails({ viewForm }) {

  const [showApproved, setShowApproved] = useState(false)

  let [JobDetails, setJobDetails] = useState({
    jobName: "",
    jobDescription: "",
    jobDeadline: "",
    jobAmmount: ""
  })

  const { isLoading, document } = useCollection("jobs")

  return (
    <JobDetailsSection>
      {
        !viewForm && <AdminOptions>
          <p onClick={() => setShowApproved(false)} style={{ borderBottom: `${!showApproved ? "2px solid #006AC2" : ""}`, color: `${!showApproved ? "#006AC2" : "#000"}` }} >All JOb</p>
          {/* <p onClick={() => setShowApproved(true)} style={{ borderBottom: `${showApproved ? "2px solid #006AC2" : ""}`, color: `${!showApproved ? "#000" : "#006AC2"}` }}>Approved Data</p> */}
        </AdminOptions>
      }

      {
        !viewForm && !showApproved && document && document.map(doc => (
          <ClientDetails key={doc?.id}>
            <p>{doc?.jobName}</p>
            <p>{doc?.jobAmmount}</p>
            <p>{doc?.jobDeadline}</p>
            <Link to={`/superadmin/jobs/${doc?.id}`}><button>Applied Creators</button></Link>
            <button>View</button>
            <button>Chat</button>
          </ClientDetails>
        ))
      }
      {
        viewForm && <JobPostingForm JobDetails={JobDetails} setJobDetails={setJobDetails} />
      }


    </JobDetailsSection>
  )
}
