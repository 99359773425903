import React, { useEffect, useState } from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { Button } from '../../../styles/Common.styled'
import { ResourceBar, ResourceCard, ResourceContent, ResourceVideo, ResourceVideoDetails } from '../../../styles/Resources.styled'


export default function ResourcesContent({ creatorsData, id }) {

  let [category, setCategory] = useState("all");
  let [resourceData, setresourceData] = useState(creatorsData?.resources);

  const { updateDocument } = useFirestore('creators');

  const handleComplete = async (data) => {

    let resourceComplete = {
      ...data,
      complete: true
    }

    let filteredResources = creatorsData?.resources?.filter(resource => resource.id !== resourceComplete.id);

    let updatedResource = [
      ...filteredResources,
      resourceComplete
    ]


    let updatedObj = {
      ...creatorsData,
      resources: updatedResource
    }

    console.log(updatedObj)
    updateDocument(id, updatedObj)
    setCategory("");
  }

  
  function handleResource() {
    let showingResource = creatorsData?.resources;
    let inCompleteResource = creatorsData?.resources?.filter(resource => resource.complete !== true);
    let completeResource = creatorsData?.resources?.filter(resource => resource.complete === true);

    if(category === "all") {
      setresourceData(showingResource)
      return ;
    }
    
    if(category === "inComplete") {
      showingResource = inCompleteResource
      console.log(showingResource);
      setresourceData(showingResource)
      return ;
    }
    if(category === "complete") {
      showingResource = completeResource
      console.log(showingResource);
      setresourceData(showingResource)
      return ;
    }
  }

  console.log(category)

  useEffect(() => {
    handleResource()
  }, [category])
  const result = resourceData ? resourceData : creatorsData?.resources

  console.log("showingResource", result)

  return (
    <ResourceContent>
      <ResourceBar>
        <p style={{background: category === "all" ? "#ccc" : "", fontWeight: category === "all" ? "700" : ""}} onClick={() => setCategory("all")}>ALL</p>
        <p style={{background: category === "complete" ? "#ccc" : "", fontWeight: category === "complete" ? "700" : ""}} onClick={() => setCategory("complete")}>Completed</p>
        <p style={{background: category === "inComplete" ? "#ccc" : "", fontWeight: category === "inComplete" ? "700" : ""}} onClick={() => setCategory("inComplete")}>in Completed</p>
      </ResourceBar>

      {
        result?.map((resource, id) => (
          <ResourceCard key={resource.id}>
            <iframe width="100%" height="315" src={resource.video} title={resource.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <ResourceVideoDetails>
              <div>{id + 1}</div>
              <p>{resource.title}</p>
              {
                resource?.complete ? <Button bg="green" color="#fff">Completed</Button> : <Button onClick={() => handleComplete(resource)}>Complete</Button>
              }

            </ResourceVideoDetails>
          </ResourceCard>
        ))
      }

    </ResourceContent>
  )
}
