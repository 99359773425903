import React from 'react'
import { AskingLeft, AskingQuestions, AskingRight } from '../../../styles/CreatorsForm.styled';
import formGif from "../../../assets/images/form.gif"
import logo from "../../../assets/images/ongshaklogoss.png"

export default function AskingTechnology({ setCreatorDetails, creatorDetails, setPage }) {

    const handleWeb = () => {
        setCreatorDetails({ ...creatorDetails, technology: "web_dev" })
        setPage(prevPage => prevPage + 1);
    }
    const handleMobile = () => {
        setCreatorDetails({ ...creatorDetails, technology: "mobile_dev" })
        setPage(prevPage => prevPage + 1);
    }

    return (
        <AskingQuestions>
            <AskingRight>
                <div>
                    <img src={logo} alt="ongshak" />
                    <p>intern publishing and graphic design, Lorem ipsum is a text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                    <h2 style={{ textAlign: "center" }}>Which development cycle do you prefer?</h2>
                    <button onClick={handleWeb}>Mobile Applications</button>
                    <button onClick={handleMobile}>Web Applications</button>
                </div>
            </AskingRight>
            <AskingLeft bg={formGif}>
                <h2>Change the Quality of your life</h2>
                <p>n publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </AskingLeft>
        </AskingQuestions>
    )
}
