import React, { useEffect } from 'react';
import { SuccesSection } from '../../styles/Common.styled';
import animation from "../../assets/images/loading.gif"
import { useNavigate } from 'react-router-dom';

export default function Success() {
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            navigate("/resources")
        }, 7000)
    }, [])
    return (
        <SuccesSection>
            <div>
                <img src={animation} alt="animation" />
            </div>

            <h2 style={{color: "green"}}>Please wait, Redirecting to Resource Page</h2>
        </SuccesSection>
    );
}
