import React from 'react'
import { Button, Container, Flex } from '../../../styles/Common.styled'
import { HeroDetails, HeroImageSection, HeroSection } from '../../../styles/Home.styled'

import heroImg from "../../../assets/images/heroSVG.png"
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection'

export default function Hero() {
    const { user } = useAuthContext();
    const navigate = useNavigate("");
    const { document: creators } = useCollection(
        "creators",
        user?.uid && ["userId", "==", user?.uid]
    )

    const actualCreator = creators.filter(creator => creator?.userId === user?.uid);

    const handleNetwork = () => {
        if (user?.uid) {
            navigate("/creators-form");
        } else {
            navigate("/sign-up");
        }
    }
    return (
        <HeroSection>
            <Container>
                <Flex>
                    <HeroDetails>
                        <h2>Improve your Programming Skills. Accelerate your Job Search.</h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here</p>
                        {
                            !actualCreator.length ? <Button onClick={handleNetwork}>Creators Network</Button> : ""
                        }
                    </HeroDetails>
                    <HeroImageSection>
                        <img src={heroImg} alt="hero image" />
                    </HeroImageSection>
                </Flex>
            </Container>
        </HeroSection>
    )
}
