import React, { useState } from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import SuperAdminContent from './Component/SuperAdminContent'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdmin({setUserData}) {
  let [view, setView] = useState(false)
  return (
    <SuperAdminSection>
      <SuperAdminSidebar />
      <SuperAdminContent setView={setView} setUserData={setUserData} view={view} />
    </SuperAdminSection>
  )
}
