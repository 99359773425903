import styled from "styled-components";

// Hero section styling start here
export const HeroSection = styled.section`
    background:  ${({ theme }) => theme.color.bg};
    min-height: 60vh;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const HeroDetails = styled.div`
    padding: 3rem 0;
    h2 {
        font-size: 4.4rem;
        margin-bottom: 2rem;    
    }
    
    p {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        h2 {
            font-size: 3.2rem;
        }
    }
`

export const HeroImageSection = styled.div`
    padding: 3rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
        img {
            width: 80%;
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        img {
            width: 60%;
        }
    }
    
`
// Hero section styling end here


// Riskfree section styling start here

export const RiskFreeSection = styled.section`
    padding: 3rem;
    background: #fff;
    text-align: center;

    h2 {
        font-size: 3.2rem;
        margin-bottom: 3rem;
    }
    
    p {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
    }
`
// Riskfree section styling end here


// Expectation section styling start here

export const ExpectationSection = styled.section`
    background:  ${({ theme }) => theme.color.bg};
    padding: 3rem;
`

export const ExpectationHeading = styled.h2`
    font-size: 3.2rem;
`
export const ExpectationDetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    >div {
        margin-bottom: 4rem;
    }

    h2 {
        font-size: 3.2rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.8rem;
    }

    article {
        width: 6rem;
        height: 6rem;
        border-radius:  100%;
        background: ${({ theme }) => theme.color.primary};

        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 4rem;
        }
    }
`

export const ExpectationDetails = styled.div`
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
`
// Expectation section styling end here



// newtwork Button section styling start here
export const NetworkSection = styled.section`
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
`

// newtwork Button section styling end here