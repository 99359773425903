import React from 'react'
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'
import { MainData } from '../../../styles/SuperAdmin.styled'
import AllSelectedCreatorDetails from './AllSelectedCreatorDetails'

export default function SelectedCreatorData({ setJobId }) {
    return (
        <MainData>
            <SuperAdminCards />
            <AllSelectedCreatorDetails setJobId={setJobId} />
        </MainData>
    )
}
