import React, { useState } from 'react'
import { ExpSection } from '../../../styles/CreatorsForm.styled';
import AskingAssessment from './AskingAssessment';
import AskingTechnology from './AskingTechnology';
import CreatorsInformation from './CreatorsInformation';

export default function ExpDetails({setExperience, setShowForm}) {

    let [page, setPage] = useState(1);
    let [creatorDetails, setCreatorDetails] = useState({
        technology: "",
        assessment: false,
        name: "",
        phone: "",
        location: ""
    });

    const pageDisplay = () => {
        if (page === 1) {
            return <AskingTechnology setCreatorDetails={setCreatorDetails} creatorDetails={creatorDetails} setPage={setPage} />
        } else if (page === 2) {
            return <AskingAssessment setCreatorDetails={setCreatorDetails} creatorDetails={creatorDetails} setPage={setPage} setExperience={setExperience} setShowForm={setShowForm} />
        } else if (page === 3) {
            return <CreatorsInformation setCreatorDetails={setCreatorDetails} creatorDetails={creatorDetails} setPage={setPage} />
        }
    }
    return (
        <ExpSection>
            {
                pageDisplay()
            }
        </ExpSection>
    )
}
