import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore';
import { Button } from '../../../styles/Common.styled';
import { ResourceList, ResourceSidebar } from '../../../styles/Resources.styled'

export default function ResourcesSidebar({ creatorsData , id}) {
    const {updateDocument} = useFirestore('creators');
    const isResourceComplete = creatorsData?.resources?.filter(resource => resource?.complete !== true);

    const handleResourceDone = () => {
      let creatorObj = {
        ...creatorsData,
        resourceDone: true
      }
      updateDocument(id, creatorObj);
    }
    return (
        creatorsData?.resources?.length && <ResourceSidebar>
            <h2>Progress <span>{creatorsData?.resources?.length}</span></h2>
            {
                creatorsData?.resources?.map((resource, id) => (
                    <ResourceList key={resource.id}>
                        <div style={{ backgroundColor: resource?.complete ? "black" : "white", color: resource?.complete ? "white" : "black" }}>{id + 1}</div>
                        <p>{resource.title}</p>
                    </ResourceList>
                ))
            }
            {
                !isResourceComplete?.length && creatorsData?.resources && !creatorsData?.resourceDone ? <Button onClick={handleResourceDone}>Press here to Complete Resources</Button> : ""
            }
        </ResourceSidebar>
    )
}
