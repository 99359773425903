import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import CreatorProfile from './Components/CreatorProfile'

export default function Profile() {
    return (
        <>
            <Navbar />
            <CreatorProfile/>
        </>
    )
}
