import React from 'react'
import { Container } from '../../../styles/Common.styled'
import { RiskFreeSection } from '../../../styles/Home.styled'

export default function RiskFree() {
  return (
    <RiskFreeSection>
        <Container>
            <h2>No Worries. Zero risk.</h2>
            <p>If you fail to complete the Assessment, no harm done. you have still opportunity to work with us by learning from our resources.</p>
            <p>You will be allowed to retake the test if you complete our resources.</p>
        </Container>
    </RiskFreeSection>
  )
}
