import styled from "styled-components";


export const ProfileButtons = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: flex-end;

    button {
        border: none;
        padding: 1.5rem 3rem;
        font-size: 1.8rem;
        background-color: ${({ bg }) => bg || (({ theme }) => theme.color.primary)};
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: .8rem;
        color: ${({ color }) => color || "#000"};
        font-weight: ${({ weight }) => weight || 600};
        border-radius: ${({ radius }) => radius || ""};
        cursor:pointer ;
    }

    button:first-child {
        background-color: #fff;
    }
`

export const ProfileForms = styled.div`
    /* display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr); */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            font-size: 1.8rem;
            font-weight: 500;
            flex: 1;
        }
        p:last-child {
            flex: 2;
        }
        
        input {
            flex: 2;
            width: 100%;
            padding: 1rem;
            outline: none;
            border: 2px solid ${({ theme }) => theme.color.primary};
            border-radius: .4rem;
            
            :focus {
            outline: none;
            background-color: rgba(249, 141, 18, .1);
        }
        }
    }
`


// new Profile Styling Start here

export const ProfileSection = styled.section`
    min-height: calc(100vh - 13vh);
    background: ${({ theme }) => theme.color.bg};
`

export const ProfileDetailSection = styled.div`
    display: grid;
    grid-template-columns: 35rem 1fr;
    gap: 2rem;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
    }
`

export const ProfileSidebar = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    align-self: start;
    min-height: 80vh;

    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;

    button {
        width: 100%;
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
        min-height: 0vh;
    }
`


export const ProfileDetails = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;

    margin-bottom: 3rem;
    div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h2 {
            font-size: 1.8rem;
        }
        p {
            font-size: 1.8rem;
        }
        img {
            width: 8rem;
            height: 8rem;
        }
    }
`

export const ProfileContent = styled.div`
    align-self: start;

    display: flex;
    flex-direction: column;
    gap: 2rem;
`


export const SidebarLogout = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        width: 3.2rem;
        height: 3.2rem;
    }

    p {
        font-size: 1.8rem;
    }
`

export const ProfileProject = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`

export const CompletedProject = styled.div`

    :not(:last-child) {
        margin-bottom: 2rem;
    }
    >p {
        font-size: 2.2rem;
        font-weight: 500;
    }

    >div {
        padding: 1rem 3rem;
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;

        div {
            display: flex;
            gap: 2rem;
            align-items:center;
            svg {
                width: 2rem;
                height: 2rem;
            }
            p {
                font-size: 1.8rem;
                line-height: 1.5;
                flex-basis: 90%;
            }
        }
    }
`

export const ProfileprojectPhase = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-around;

    div {
        display: flex;
        gap: 1rem;
        font-size: 1.6rem;

        span {
            font-weight: 600;
        }
    }
`

export const ProfileInfo = styled.div`
    background: #fff;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

    >p {
        font-size:  2.2rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }
`

// creators Rules

export const CreatorRule = styled.div`
    
    >div {
        display: flex;
        justify-content: space-between;
        gap: 4rem;

        :not(:last-child) {
            margin-bottom: 3rem;
        }

       div {
            h6 {
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }

            p {
                font-size: 1.8rem;
                color: #555;
                line-height: 1.5;
            }
            flex-basis: 90%;
       }

        svg {
            width: 3rem;
            height: 3rem;
            fill: ${({ theme }) => theme.color.primary};
        }
    }

`