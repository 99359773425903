import React from 'react'
import { FooterLinks, FooterLogo } from '../../styles/Footer.styled'
import { Container, GridFour } from '../../styles/Common.styled'

export default function Footer() {
    return (
        <>
            <Container>
                <GridFour>
                    <FooterLogo>
                        <h2>ongshak</h2>
                    </FooterLogo>
                    <FooterLinks>
                        <ul>
                            <li>About us</li>
                            <li>Team</li>
                            <li>Career</li>
                            <li>Media</li>
                        </ul>
                    </FooterLinks>
                    <FooterLinks>
                        <ul>
                            <li>Our Services</li>
                            <li>App</li>
                            <li>Website</li>
                            <li>loT</li>
                        </ul>
                    </FooterLinks>
                    <FooterLinks>
                        <ul>
                            <li>Help</li>
                            <li>FAQ</li>
                            <li>Terms & Conditions</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </FooterLinks>
                </GridFour>
            </Container>
        </>
    )
}
