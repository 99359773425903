// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcjLiqEgr0sHncgrjVxzMATOL2Glq8Ykk",
  authDomain: "mvp-2022-webapp.firebaseapp.com",
  projectId: "mvp-2022-webapp",
  storageBucket: "mvp-2022-webapp.appspot.com",
  messagingSenderId: "90464684415",
  appId: "1:90464684415:web:bea3fa72bfe499b216b24a",
  measurementId: "G-34HCQV5J44"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const timeStamp = Timestamp;
export const projectStorage = getStorage();