import React, { useState } from 'react'
import { SignUpForms, SignupFormSection, SignUpGoogle } from '../../../styles/SignUp.styled'
import { Link } from 'react-router-dom';

import { useSignup } from '../../../hooks/useSignup';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useSignInWithGoogle } from '../../../hooks/useSignInWithGoogle';
import { FaRegEye, FaRegEyeSlash, FaGooglePlusG } from 'react-icons/fa';

export default function SIgnUpForm() {
    let [displayName, setDisplayName] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [type, setType] = useState("password")
    let [customError, setCustomError] = useState("");


    const { user } = useAuthContext()
    const { handleSignInWithGoogle, handleSignOutWithGoogle } = useSignInWithGoogle()
    const { error, signup, isPending } = useSignup();


    const reset = () => {
        setEmail('');
        setPassword('');
        setDisplayName("");
    }

    const handleSubmit = e => {
        e.preventDefault();
        setCustomError("")
        if (email === "" || password === "" || displayName === "") {
            setCustomError("Input field have to be fill up");
            return
        }
        signup(email, password, displayName);
        reset();
    }
    return (
        <SignupFormSection>
            <SignUpForms onSubmit={handleSubmit}>
                <div>
                    <h2>Get Started With <span>Ongshak</span></h2>
                    <p>Improve your Programming Skills. Accelerate your Job Search.</p>
                </div>
                <label>
                    <input type="text" placeholder='Creators name' value={displayName} onChange={e => setDisplayName(e.target.value)} />
                </label>
                <label>
                    <input type="email" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label>
                    <input type={type} placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />

                    {/* Password showing icon */}
                    {
                        type == "password" && <FaRegEye onClick={() => setType("text")} />
                    }
                    {
                        type == "text" && <FaRegEyeSlash onClick={() => setType("password")} />
                    }
                </label>

                {/* Sign up button */}
                {
                    isPending && <input type="submit" disabled value="Processing" />
                }
                {
                    !isPending && <input type="submit" value="Create an Account" />
                }

                <p>Already have an Account? <Link to="/login"><span>LogIn</span></Link></p>

                {/* error showing while error appear from custom hook and custom error state */}
                {
                    error && <p style={{ color: "red" }}>{error}</p>
                }
                {
                    customError && <p style={{ color: "red" }}>{customError}</p>
                }

                {/* google authentication  */}
                {
                    !user?.email ?
                        <SignUpGoogle onClick={() => handleSignInWithGoogle()}>
                            <p>Sign In With Google</p>
                            <FaGooglePlusG />
                        </SignUpGoogle> : <SignUpGoogle onClick={() => handleSignOutWithGoogle()}>
                            <p>Sign Out From Google</p>
                            <FaGooglePlusG />
                        </SignUpGoogle>
                }
            </SignUpForms>
        </SignupFormSection>
    )
}
