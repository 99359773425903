import React from 'react'
import { CompletedProject, CreatorRule, ProfileProject } from '../../../styles/Profile.styled'
import { FaRibbon } from "react-icons/fa";

export default function CreatorRules() {
  return (
    <ProfileProject>
      <h2 style={{fontSize: "2.2rem", marginBottom: "2rem"}}>Some of our Rules</h2>
      <CreatorRule>
        <div>
          <FaRibbon />
          <div>
            <h6>Deadline</h6>
            <p>If you fail to complete the Assessment, no harm done. you have still opportunity to work with us by learning from our resources.</p>
          </div>
        </div>
        <div>
          <FaRibbon />
          <div>
            <h6>Job visibility</h6>
            <p>if you have any project running then you could not be able to apply for any other job.</p>
          </div>
        </div>
      </CreatorRule>
    </ProfileProject>
  )
}
