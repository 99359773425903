import React from 'react'
import { Sidebar, SidebarLinks } from '../../../styles/SuperAdmin.styled'
import {Link} from "react-router-dom"

import homeIcon from "../../../assets/images/homeIcon.png"
import logoutIcon from "../../../assets/images/logoutIcon.png"
import details from "../../../assets/images/orderIcon.png"

export default function SuperAdminSidebar() {
    return (
        <Sidebar>
            <SidebarLinks>
                <div>
                    <Link to = "/superadmin">
                        <img src={homeIcon} alt="home" />
                    </Link>
                    <Link to ="/superadmin/jobs">
                        <img src={details} alt="jobs" />
                    </Link>
                    <Link to ="/superadmin/creators">
                        <img src={details} alt="creators" />
                    </Link>
                    <Link to ="/superadmin/selectedCreators">
                        <img src={details} alt="selected Creators" />
                    </Link>
                </div>
                <div>
                    <img src={logoutIcon} alt="logout" />
                </div>
            </SidebarLinks>
        </Sidebar>
    )
}
