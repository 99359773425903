import React from 'react'
import {Link} from "react-router-dom"
import { Button, Container } from '../../../styles/Common.styled'
import { IndividualJobSection, JobDetail, JobDetailsSection, JobImageSection } from '../../../styles/Jobs.styled'

export default function IndividualJob({selectingJob}) {
    return (
        <IndividualJobSection>
            <JobImageSection></JobImageSection>
            <JobDetailsSection>
                <h2 style={{textAlign: "center"}}>{selectingJob?.jobName}</h2>
                <JobDetail>
                    <p>Job Location: </p>
                    <p>Remote</p>
                    <p>Job Description: </p>
                    <p>{selectingJob?.jobDescription}</p>
                    <p>Deadline: </p>
                    <p>{selectingJob?.jobDeadline}</p>
                    <Link to={`/jobs/${selectingJob?.id}/apply`}><Button>Apply</Button></Link>
                </JobDetail>

            </JobDetailsSection>
        </IndividualJobSection>
    )
}
