import React from 'react'
import { AskingLeft, AskingQuestions, AskingRight } from '../../../styles/CreatorsForm.styled'
import formGif from "../../../assets/images/form.gif"
import logo from "../../../assets/images/ongshaklogoss.png"

export default function AskingAssessment({ setCreatorDetails, creatorDetails, setPage,setExperience, setShowForm }) {

    const handleYesAssessment = () => {
        setCreatorDetails({ ...creatorDetails, technology: "web_dev", assessment: true })
        setPage(prevPage => prevPage + 1);
    }
    const handleNoAssessment = () => {
        setExperience(false);
        setShowForm(true);
    }

    return (
        <AskingQuestions>
            <AskingLeft bg={formGif}>
                <h2>Change the Quality of your life</h2>
                <p>n publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </AskingLeft>
            <AskingRight>
                <div>
                    <img src={logo} alt="ongshak" />
                    <h3 style={{ textAlign: "center" }}>In order to get an idea of how far you have progressed, we need you to complete a small introductory project for us where we will assess your coding skills. Do you want to take the assessment test?</h3>
                    <button onClick={handleYesAssessment}>Yes, I agree</button>
                    <button onClick={handleNoAssessment}>No, I don't</button>
                </div>
            </AskingRight>
        </AskingQuestions>
    )
}
