import styled from "styled-components";

export const SignupFormSection = styled.section`
    min-height: 55vh;
    max-width: 600px;
    background: ${({ theme }) => theme.color.bg};
    margin: 15rem auto;
    padding: 4rem;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        margin: 10rem 5rem;
        padding: 2rem;
    }

`

export const SignUpForms = styled.form`
    width: 80%;
    div {
        text-align: center;
        h2 {
            font-size: 2.8rem;
            margin-bottom: 2rem;
            span {
                color: ${({ theme }) => theme.color.primary};
            }
        }
        p {
            margin-bottom: 4rem;
            line-height: 1.5;
        }
    }

    p {
        font-size: 1.6rem;
        margin-top: 1rem;
        span {
            color: ${({ theme }) => theme.color.primary};
            font-weight: 700;
            text-decoration: underline;
        }
    }

    label {
        font-size: 1.8rem;
        margin-top: 1rem;
        display: block;
        font-weight: 500;
        position: relative;

        svg {
            position: absolute;
            right: .5rem;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    input {
        width: 100%;
        border: 1px solid #ddd;
        border-radius: .8rem;
        padding: 1.5rem;
        font-size: 1.8rem;
        transition: .1s;

        :focus {
            outline: none;
        }
    }

    input[type=submit] {
        margin-top: 3rem;
        background: ${({ theme }) => theme.color.primary} ;
        font-size: 1.8rem;
        font-weight: 600;
        cursor: pointer;
    }
`


export const SignUpGoogle = styled.div`
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 1rem;
    margin: 2rem 0;
    width: 100%;
    border-radius: .8rem;
    cursor: pointer;

    svg {
        margin-left: 1rem;
        color: #fff;
        width: 3rem;
        height: 3rem;

    }

    p {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin: 0 !important;
        @media (max-width: ${({ theme }) => theme.smallMobile}) {
            font-size: 1.4rem;
            }
    }
`