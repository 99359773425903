import styled from "styled-components";

export const SuperAdminSection = styled.div`
    min-height: 100vh;
    background: #fff;
    width: 100vw;
    color: #000;

    display: flex;
`

export const Sidebar = styled.div`
    width: 10rem;
    background-color: #fff;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 3rem;
`

export const SidebarLinks = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        width: 3rem;
        height: 3rem;
        margin: 1rem 0;
        cursor: pointer;
    }
`

export const MainSuperAdmin = styled.div`
    width: calc(100% - 10rem);
    /* background: #ddd; */
    
    display: grid;
    grid-template-columns: 1fr 400px;
    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
`
export const MainData = styled.div`
    position: relative;
    background: #fff;
    padding: 2rem;
    border-right: 2px solid #ddd;
    margin: 1rem;
    @media (max-width: 1200px) {
        border-bottom: 2px solid #ddd;
        border-right: none;
    }
`

export const Details = styled.div`
    padding: 3rem;
`

export const DetailsHeading = styled.div`
    color: #006AC2;
    font-size: 3.2rem;
`

export const DetailsData = styled.div`
    background: #fff;
    color: #000;
    p {
        font-size: 1.8rem;
        margin: 1rem 0;
    }
    p:nth-child(2n +1){
        font-weight: 700;
    }
`

export const Cards = styled.div`
    display: flex;
    gap: 8rem;
`

export const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 6rem 4rem;
    border-radius: .4rem;
    :first-child {
        background: #CCF3FF;
    }
    :last-child {
        background: #D3D2FF;
    }

    h2 {
        font-size: 2.8rem;
    }

`

export const AdminOptions = styled.div`
    display: flex;
    font-size: 2rem;
    /* border-bottom: 2px solid #ddd; */
    margin: 3rem 0;

    p:first-child {
        margin-right: 2rem;
    }
    p{
        cursor: pointer;
    }
`

export const ClientDetails = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-items: center;

    padding: 2rem 0;

    img {
        width: 4rem;
    }
    p {
        font-size: 1.6rem;
    }

    button {
        border: none;
    background-color: #006AC2;
    border-radius: .8rem;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    color: #fff;
    cursor: pointer;
    margin: 0 2rem;
    transition: all .3s;
    :focus, :hover {
        box-shadow: 1px 1px 5px 0 #006AC2;
        outline: none;
    }
    }

    svg {
        width: 2rem;
        height: 2rem;
    }
`

export const AnalyzeMain = styled.div`
    width: calc(100% - 10rem);
    p {
        text-align: center;
        font-size: 3.2rem;
        font-weight: 700;
        cursor: pointer;
    }
`

export const AnalyzeData = styled.div`
    width: 40%;
    margin: 30rem auto;
    padding: 5rem 3rem;
    border: 5px solid #006AC2;

    input {
    width: 100%;
    border: 1px solid #006AC2;
    padding: 1rem;
    border-radius: .4rem;
    
    :not(:last-child) {
        margin-bottom: 1rem;
    }

    :focus {
        outline: none;
    }
    }

    button {
        border: none;
    background-color: #006AC2;
    border-radius: .8rem;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    color: #fff;
    cursor: pointer;
    transition: all .3s;
    :focus, :hover {
        box-shadow: 1px 1px 5px 0 #006AC2;
        outline: none;
    }
    }
`

export const Chatbox = styled.div`
    border: 2px solid #006AC2;
    width: 40%;
    margin: 30rem auto;
    padding: 3rem 2rem;
    border-radius: .4rem;

    p {
        font-size: 1.6rem;
        :last-child {
            margin-bottom: 2rem;
        }
    }

    input {
        display: block;
        width: 100%;
        border: 1px solid #006AC2;
        border-radius: .4rem;
        padding: 1rem;
        border-radius: .4rem;
        
        :not(:last-child) {
            margin-bottom: 1rem;
        }

    :focus {
        outline: none;
    }
}

    button {
        border: none;
        background-color: #006AC2;
        border-radius: .8rem;
        font-size: 1.6rem;
        padding: 1rem 2rem;
        color: #fff;
        cursor: pointer;
        transition: all .3s;
        text-align: right;
        :focus, :hover {
            box-shadow: 1px 1px 5px 0 #006AC2;
            outline: none;
        }
    }
`

export const AddedJob = styled.div`
    position: absolute;
    width: 5rem;
    height: 5rem;
    box-shadow: 2px 2px 10px rgba(0,0,0, .2);
    border-radius: 50%;
    bottom: 3rem;
    right: 3rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 3rem;
        height: 3rem;
    }
`


export const JobDetailsSection = styled.div`
    margin: 5rem 0;
    padding: 2rem;
`

export const JobForm = styled.form`
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0,0,0,.1);
    width: 50%;
    margin: 0 auto;
    padding: 3rem 2rem;

    label {
        display: block;
        margin: 1rem 0;
    }

    p {
        margin-bottom: .5rem;
        font-size: 1.6rem;
        font-weight: 700;
    }

    input {
        width: 100%;
        border: 1px solid #ddd;
        padding: 1rem;
    }

    input:focus {
        outline: none;
    }
`