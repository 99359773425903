import React from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import AppliedJob from './Component/AppliedJob'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdminAppliedJOb() {
    return (
        <SuperAdminSection>
            <SuperAdminSidebar />
            <AppliedJob />
        </SuperAdminSection>
    )
}
