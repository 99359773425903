import React, { useState } from 'react'
import { SubmittingSection } from '../../../styles/ProjectDetails.styled'
import { Button } from '../../../styles/Common.styled'
import { useFirestore } from '../../../hooks/useFirestore'

export default function ProjectSubmittingForm({ setError, selectCreator }) {
  const [links, setLinks] = useState({
    githubLink: "",
    projectLink: ""
  })

  const { updateDocument } = useFirestore("selectedCreators")

  const handleSubmit = () => {
    if (links.githubLink === "" || links.projectLink === "") {
      setError("please fill all the required field");
      return
    }

    let obj = {
      ...selectCreator,
      ...links,
      isDevelopmentDone: true,
      isTestingDone: true,
      isComplete: true,
    }

    updateDocument(selectCreator?.id, obj)
    setError("");
    setLinks({
      githubLink: "",
      projectLink: ""
    })
  }
  return (
    <>
      <SubmittingSection>
        <div>
          <p>Github Repo: *</p>
          <input type="text" placeholder='eg. https://github.com' value={links.githubLink} onChange={e => setLinks({ ...links, githubLink: e.target.value })}/>
        </div>
        <div>
          <p>Live Link: *</p>
          <input type="text" placeholder='eg. https://project.com' value={links.projectLink} onChange={e => setLinks({ ...links, projectLink: e.target.value })} />
        </div>
      </SubmittingSection>
      <Button onClick={handleSubmit}>Submit</Button>
    </>
  )
}
