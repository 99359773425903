import React from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import SelectedCreatorsContent from './Component/SelectedCreatorsContent'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdminSelectedCreators() {
    return (
        <SuperAdminSection>
            <SuperAdminSidebar />
            <SelectedCreatorsContent />
        </SuperAdminSection>
    )
}
