import React, { useState } from 'react'
import { MainData, AddedJob } from '../../../styles/SuperAdmin.styled'
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'
import AllJobDetails from './AllJobDetails'

import { FaPlus, FaCut } from 'react-icons/fa';

export default function SuperAdminJobData() {
    let [viewForm, setViewForm] = useState(false);
    return (
        <MainData>
            <SuperAdminCards />
            <AllJobDetails viewForm={viewForm} />

            {/* this is abosulute plus icon  */}
            <AddedJob onClick={() => setViewForm(prevView => !prevView)}>
                {
                    viewForm ? <FaCut /> : <FaPlus />
                }

            </AddedJob>
        </MainData>
    )
}
