import React, { useEffect } from 'react'
import { Container, GreetingsSection, Button, GreetSection } from '../../../styles/Common.styled'
import { ResourceContainer, ResourceSection } from '../../../styles/Resources.styled'
import ResourcesContent from './ResourcesContent'
import ResourcesSidebar from './ResourcesSidebar'
import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFirestore } from '../../../hooks/useFirestore'
import { useNavigate } from 'react-router-dom'
import welcomeImg from "../../../assets/images/resource.jpg"

const resources = [
    {
        id: 1,
        category: "react",
        videos: [
            {
                id: 1,
                title: "React crash course",
                video: `https://youtube.com/embed/Dorf8i6lCuk`
            },
            {
                id: 2,
                title: "React 15 project",
                video: `https://youtube.com/embed/a_7Z7C_JCyo`
            },
            {
                id: 3,
                title: "React full playlist",
                video: `https://youtube.com/embed/playlist?list=PL4cUxeGkcC9gZD-Tvwfod2gaISzfRiP9d`
            },
            {
                id: 4,
                title: "React 9 hours full course",
                video: `https://youtube.com/embed/RVFAyFWO4go`
            },

        ]
    },
    {
        id: 2,
        category: "flutter",
        videos: [
            {
                id: 1,
                title: "Flutter full course",
                video: `https://youtube.com/embed/x0uinJvhNxI`
            },
            {
                id: 2,
                title: "Flutter full playlist",
                video: `https://youtube.com/embed/1ukSR1GRtMU`
            },
            {
                id: 3,
                title: "Flutter 37 hours tutorial",
                video: `https://youtube.com/embed/VPvVD8t02U8`
            },
        ]
    }
];

export default function AllResources() {
    const { user } = useAuthContext();

    const { document: creators } = useCollection(
        "creators",
        user?.uid && ["userId", "==", user?.uid]
    )
    // const  creators = document?.filter(creator => creator?.userId == user?.uid);
    const { updateDocument } = useFirestore("creators");

    const navigate = useNavigate("")

    // Filterring type of creator from all Creators
    const creatorTypeC = creators?.filter(creator => creator?.type === "type_C");
    const creatorTypeD = creators?.filter(creator => creator?.type === "type_D");

    const resourceComplete = (creatorTypeC.length && creatorTypeC[0]?.resourceDone) || (creatorTypeD.length && creatorTypeD[0]?.resourceDone)


    const handleTakeAssessment = () => {
        const creatorObj = {
            ...creators[0],
            type: "assessment"
        }

        updateDocument(creators[0]?.id, creatorObj);
        navigate("/")
    }

    const shownResources = resources?.filter(resource => resource.category.toLowerCase() == creators[0]?.technology.toLowerCase());

    useEffect(() => {
        if (!creators[0]?.resources) {
            const updateObj = {
                ...creators[0],
                resources: shownResources[0]?.videos
            }
            updateDocument(creators[0]?.id, updateObj);
        }
    }, [creators[0]])

    return (
        <ResourceSection>
            <Container>
                {/* if resource is available then this portion will be work */}
                <ResourceContainer>
                    <ResourcesSidebar creatorsData={creators[0]} id={creators[0]?.id} />
                    <ResourcesContent creatorsData={creators[0]} id={creators[0]?.id} />

                </ResourceContainer>
                {
                    resourceComplete ? <GreetingsSection>
                        <p>You’re eligible for taking an assessment test</p>
                        <p style={{ fontWeight: "700", marginBottom: "2rem" }}>Do you want to go for it?</p>
                        <Button onClick={handleTakeAssessment} bg="#fff">Yes</Button>
                    </GreetingsSection> : ""
                }


                {/* if resource is not available then this portion will be work */}
                {
                    !creators[0]?.resources ? <GreetSection>
                        <div>
                            <img src={welcomeImg} alt="welcome Img" />
                            <p>Hey, <span>{creators[0]?.name}</span></p>
                            <p>Stay with us, we’ll update the resources asap!</p>
                        </div>
                    </GreetSection> : ""
                }
            </Container>
        </ResourceSection>
    )
}
