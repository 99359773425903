import React, { useState } from 'react'
import { MainSuperAdmin } from '../../../styles/SuperAdmin.styled'
import SuperAdminCreatorsData from './SuperAdminCreatorsData'
import SuperAdminJobData from './SuperAdminJobData'
import ViewCreatorsData from './ViewCreatorsData';

export default function SuperAdminCreatorsContent() {
    let [viewData, setViewData] = useState("");
    return (
        <MainSuperAdmin>
            <SuperAdminCreatorsData setViewData={setViewData} />
            <ViewCreatorsData viewData={viewData} />
        </MainSuperAdmin>
    )
}
