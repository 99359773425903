import React, { useState } from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useSignInWithGoogle } from '../../../hooks/useSignInWithGoogle'
import { useLogin } from '../../../hooks/useLogin'
import { SignUpForms, SignupFormSection, SignUpGoogle } from '../../../styles/SignUp.styled'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegEye, FaRegEyeSlash, FaGooglePlusG } from 'react-icons/fa';
import { useForgotPassword } from '../../../hooks/useForgotPassword'

export default function LoginForm() {
    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")
    let [customError, setCustomError] = useState("");
    let [type, setType] = useState("password");


    const { user } = useAuthContext()
    const { error, Login, isPending } = useLogin()
    const { handleSignInWithGoogle, handleSignOutWithGoogle } = useSignInWithGoogle()
    const {sendPasswordReset,  error:forgotPasswordErr, success} = useForgotPassword();

    const navigate = useNavigate("")

    console.log(error, isPending)

    const handleLogin = e => {
        e.preventDefault();
        setCustomError("")
        if (email === "" || password === "") {
            setCustomError("Input field have to be fill up");
            return
        }
        Login(email, password);
        navigate("/creators-form")
        reset();
    }

    const reset = () => {
        setEmail('')
        setPassword('')
    }

    const handleForgotPassword = () => {
        sendPasswordReset(email);
    }

    return (
        <SignupFormSection>
            <SignUpForms onSubmit={handleLogin}>
                <div>
                    <h2>Get Started With <span>Ongshak</span></h2>
                    <p>Improve your Programming Skills. Accelerate your Job Search.</p>
                </div>
                <label>
                    <input type="email" placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
                </label>
                <label>
                    <input type={type} placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />

                    {/* Password showing icon */}
                    {
                        type == "password" && <FaRegEye onClick={() => setType("text")} />
                    }
                    {
                        type == "text" && <FaRegEyeSlash onClick={() => setType("password")} />
                    }
                </label>

                {/* Login button */}
                {
                    isPending && <input type="submit" value="Processing" />
                }
                {
                    !isPending && <input type="submit" value="Login" />
                }
                <p>Don't have an Account? <Link to="/sign-up"><span>SignUp</span></Link></p>
                <p style={{ cursor: "pointer", color: "#F98D12" }} onClick={handleForgotPassword}>Forgot Password?</p>

                {/* error showing while error appear from custom hook and custom error state */}
                {
                    error && <p style={{ color: "red" }}>{error}</p>
                }
                {
                    forgotPasswordErr && <p style={{ color: "red" }}>{forgotPasswordErr}</p>
                }
                {
                    customError && <p style={{ color: "red" }}>{customError}</p>
                }
                {
                    success && <p style={{ color: "green" }}>{success}</p>
                }

                {/* google authentication  */}
                {
                    !user?.email ?
                        <SignUpGoogle onClick={() => handleSignInWithGoogle()}>
                            <p>Sign In With Google</p>
                            <FaGooglePlusG />
                        </SignUpGoogle> : <SignUpGoogle onClick={() => handleSignOutWithGoogle()}>
                            <p>Sign Out From Google</p>
                            <FaGooglePlusG />
                        </SignUpGoogle>
                }
            </SignUpForms>
        </SignupFormSection>
    )
}
