import React from 'react'
import { Card, Cards } from '../../styles/SuperAdmin.styled'
import Sun from "../../assets/images/sun.png"

export default function SuperAdminCards() {
    return (
        <Cards>
            <Card>
                <h2>Tracking all Project</h2>
                <img src={Sun} alt="sun icon" />
            </Card>
            <Card>
                <h2>Tracking all Project</h2>
                <img src={Sun} alt="sun icon" />
            </Card>
        </Cards>
    )
}
