import styled from "styled-components";

export const ProjectDetailSection = styled.section`
    h2 {
        font-size: 3.2rem;
        margin-bottom: 2rem;
    }
    
    p {
        font-size: 1.8rem;
    }
    `

export const ProjectIngrediants = styled.div`
    margin: 3rem 0;
    display: flex;
    gap: 2rem;
    align-items: center;

    div {
        padding: 2rem;
        min-height: 11rem;
        background:  ${({ theme }) => theme.color.bg};
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p:first-child {
            font-weight: 700;
            margin-bottom: 1rem;
        }
    }
`

export const Phase = styled.div`
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    justify-content: space-between;

    button {
        flex: 1;
        padding: 2rem;
        border: none;
        background: black;
        color: #fff;
        font-size: 1.6rem;
        border-radius: .4rem;
        cursor: pointer;
    }
`

export const SubmittingSection = styled.div`
    margin: 2rem 0;
    width: 100%;

    display: flex;
    gap: 2rem;
    justify-content: space-between;

    div {
        flex: 1;

        input {
            width: 100%;
            border: 1px solid #ddd;
            padding: 1rem;
        }

        input:focus {
            outline: none;
        }
    }
`

export const ProjectCompleteSection = styled.div`
    width: 50%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 5rem 2rem; 
    border-radius: .8rem;
    margin-bottom: 2rem;

    h2 {
        font-size: 1.8rem;

        span {
            color: red; 
        }
    }

    p {
        padding: 1rem 0;
        font-size: 1.8rem;
    }

    div {
        margin: 0 2rem;
        display: flex;
        flex-direction: column;
        p {
            font-size: 1.8rem;
            color: #555;

            display: flex;
            gap: 2rem;
            align-items: center;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 80%;
    }
`