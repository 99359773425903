import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import ProjectDetails from './Components/ProjectDetails'

export default function Projects() {
  return (
    <>
        <Navbar />
        <ProjectDetails />
    </>
  )
}
