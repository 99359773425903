import React from 'react'
import Navbar from '../../organism/shared/Navbar'
import LoginForm from './Components/LoginForm'

export default function Login() {
  return (
    <>
        <Navbar />
        <LoginForm />
    </>
  )
}
