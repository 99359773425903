import React from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import SuperAdminJobContent from './Component/SuperAdminJobContent'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdminJobsPage() {
    return (
        <SuperAdminSection>
            <SuperAdminSidebar />
            <SuperAdminJobContent />
        </SuperAdminSection>
    )
}
