import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'
// import { Textarea } from '../../../styles/Form.styled';
import { JobForm, MainData } from '../../../styles/SuperAdmin.styled'

export default function CreatorAssessmentForm() {
    const { creatorID } = useParams();
    const [assessment, setAssessment] = useState({
        projectName: "",
        projectDescription: "",
        projectDeadline: "",
        figmaLink: ""
    })

    const { updateDocument } = useFirestore("creators");
    const { document: allCreators } = useCollection("creators");


    const individualCreator = allCreators.filter(creator => creator?.id === creatorID);
    console.log(allCreators);
    console.log(individualCreator);


    const handleSubmit = e => {
        e.preventDefault();
        const creatorObj = {
            ...individualCreator[0],
            assessment,
            type: "give_assessment",
            assessmentGithub: "",
            assessmentProject: ""
        }

        updateDocument(individualCreator[0]?.id, creatorObj);
        setAssessment({
            projectName: "",
            projectDescription: "",
            projectDeadline: "",
            figmaLink: ""
        })
    }
    return (
        <MainData>
            <SuperAdminCards />
            <JobForm onSubmit={handleSubmit}>
                <label>
                    <p>project Name:</p>
                    <input type="text" placeholder='eg. Blockchain Developer' value={assessment.projectName} onChange={e => setAssessment({ ...assessment, projectName: e.target.value })} />
                </label>

                <label>
                    <p>project Description:</p>
                    <textarea bg="#fff" placeholder='Description' value={assessment.projectDescription} onChange={e => setAssessment({ ...assessment, projectDescription: e.target.value })} />
                </label>

                <label>
                    <p>Deadline:</p>
                    <input type="date" value={assessment.projectDeadline} onChange={e => setAssessment({ ...assessment, projectDeadline: e.target.value })} />
                </label>

                <label>
                    <p>Figma Link</p>
                    <input type="text" value={assessment.figmaLink} onChange={e => setAssessment({ ...assessment, figmaLink: e.target.value })} />
                </label>

                <input type="submit" value="give Assessment" />
            </JobForm>
        </MainData>
    )
}
