import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection'
import { AdminOptions, ClientDetails, JobDetailsSection } from '../../../styles/SuperAdmin.styled'
import { FaGithub, FaShareSquare, FaRegFolder } from 'react-icons/fa';
import { useFirestore } from '../../../hooks/useFirestore';
import SelectCreators from "./SelectCreators"

export default function AppliedJobDataDetails() {
    const { jobID } = useParams();
    const [showApproved, setShowApproved] = useState(false);
    const [showSelectForm, setShowSelectForm] = useState(false);
    const [selectedCreator, setSelectedCreator] = useState(null);

    const { document: appliedJobs } = useCollection("jobApply");
    const { document: allJobs } = useCollection("jobs");
    const { updateDocument } = useFirestore("jobApply");


    // Filter specific job data from ALl job
    const job = allJobs.filter(job => job?.id === jobID)

    // Filter specific job applied data from ALl job
    const specificJobApplied = appliedJobs.filter(doc => doc?.job === job[0]?.jobName);

    const handleSelect = (data) => {
        setShowSelectForm(true);
        setSelectedCreator(data);
    }


    const handleReject = (id) => {
        const obj = {
            ...specificJobApplied[0],
            selected: false,
            rejected: true,
        }

        updateDocument(id, obj);
    }




    return (
        <JobDetailsSection>
            {!showSelectForm && <AdminOptions>
                <p onClick={() => setShowApproved(false)} style={{ borderBottom: `${!showApproved ? "2px solid #006AC2" : ""}`, color: `${!showApproved ? "#006AC2" : "#000"}` }} >Applied Creators</p>
            </AdminOptions>}

            {
                !showSelectForm && !showApproved && specificJobApplied && specificJobApplied?.map(doc => (
                    <ClientDetails key={doc?.id}>
                        <p>{doc?.fullName}</p>

                        <a href={doc?.github} target="_blank"><FaGithub /></a>
                        <a href={doc?.project} target="_blank"><FaShareSquare /></a>
                        <a href={doc?.resume} target="_blank"><FaRegFolder /></a>
                        <button style={{ background: `${doc?.selected ? "green" : ""}` }} onClick={() => handleSelect(doc)}>Select</button>
                        <button style={{ background: `${doc?.rejected ? "red" : ""}` }} onClick={() => handleReject(doc?.id)}>Reject</button>
                    </ClientDetails>
                ))
            }
            {
                showSelectForm && <SelectCreators selectedCreator={selectedCreator} job={job[0]} />
            }
        </JobDetailsSection>
    )
}
