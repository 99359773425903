import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFirestore } from '../../../hooks/useFirestore';
import { Button } from '../../../styles/Common.styled'
import { SubmittingSection } from '../../../styles/ProjectDetails.styled'

export default function AssessmentSubmittingForm({ giveAssessment }) {

    const [links, setLinks] = useState({
        assessmentGithub: "",
        assessmentProject: ""
    })
    const [error, setError] = useState("");

    const { updateDocument } = useFirestore("creators")
    const navigate = useNavigate("")

    const handleSubmit = () => {
        setError("");
        if (links.assessmentGithub === "" || links.assessmentProject === "") {
            setError("please fill all the required field");
            return
        }

        let obj = {
            ...giveAssessment,
            ...links,
            type: "submit_assessment",
            isDevelopmentDone: true,
            isTestingDone: true,
            isComplete: true,
        }

        updateDocument(giveAssessment?.id, obj)
        setError("");
        setLinks({
            githubLink: "",
            projectLink: ""
        });
        navigate("/");
    }
    return (
        <>
            <SubmittingSection>
                <div>
                    <p>Github Repo: *</p>
                    <input type="text" placeholder='eg. https://github.com' value={links.assessmentGithub} onChange={e => setLinks({ ...links, assessmentGithub: e.target.value })} />
                </div>
                <div>
                    <p>Live Link: *</p>
                    <input type="text" placeholder='eg. https://project.com' value={links.assessmentProject} onChange={e => setLinks({ ...links, assessmentProject: e.target.value })} />
                </div>
            </SubmittingSection>
            <p style={{ color: "red" }}>{error}</p>
            <Button onClick={handleSubmit}>Submit</Button>
        </>
    )
}
