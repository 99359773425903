import React from 'react'
import { SuperAdminSection } from '../../styles/SuperAdmin.styled'
import CreatorAssessment from './Component/CreatorAssessment'
import SuperAdminSidebar from './Component/SuperAdminSidebar'

export default function SuperAdminIndividualCreator() {
    return (
        <SuperAdminSection>
            <SuperAdminSidebar />
            <CreatorAssessment />
        </SuperAdminSection>
    )
}
