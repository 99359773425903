import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore';
import { JobForm } from '../../../styles/SuperAdmin.styled';

export default function JobPostingForm({ JobDetails, setJobDetails }) {
    // custom hook
    const { addDocument } = useFirestore("jobs");

    const handleSubmit = e => {
        e.preventDefault();

        const jobObj = {
            ...JobDetails,
            creatorsNeed: true
        }

        addDocument(jobObj);
        reset();
    }

    const reset = () => {
        setJobDetails({
            jobName: "",
            jobDescription: "",
            jobDeadline: "",
            jobAmmount: ""
        })
    }

    return (
        <JobForm onSubmit={handleSubmit}>
            <label>
                <p>Job Name:</p>
                <input type="text" placeholder='eg. Blockchain Developer' value={JobDetails.jobName} onChange={e => setJobDetails({ ...JobDetails, jobName: e.target.value })} />
            </label>

            <label>
                <p>Job Description:</p>
                <textarea bg="#fff" placeholder='Description' value={JobDetails.jobDescription} onChange={e => setJobDetails({ ...JobDetails, jobDescription: e.target.value })} />
            </label>

            <label>
                <p>Deadline:</p>
                <input type="date" value={JobDetails.jobDeadline} onChange={e => setJobDetails({ ...JobDetails, jobDeadline: e.target.value })} />
            </label>

            <label>
                <p>Ammount</p>
                <input type="number" value={JobDetails.jobAmmount} onChange={e => setJobDetails({ ...JobDetails, jobAmmount: e.target.value })} />
            </label>

            <input type="submit" value="Post JOb" />
        </JobForm>
    )
}
