import React, { useState, useEffect } from 'react'
import Progress from '../../organism/progress/Progress';
import Navbar from '../../organism/shared/Navbar'
import AskingExp from './Components/AskingExp'
import ExpDetails from './Components/ExpDetails';
import NoExpDetails from './Components/NoExpDetails'

import { useAuthContext } from "../../../src/hooks/useAuthContext"
import { Container, FullScreenMessage } from '../../styles/Common.styled';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';

export default function CreatorsForm() {
    let [experience, setExperience] = useState(false);
    let [showForm, setShowForm] = useState(false);
    let [showLoading, setShowLoading] = useState(true);
    let [showExperienceDetails, setShowExperienceDetails] = useState(false);

    const { user } = useAuthContext()
    const navigate = useNavigate("")
    const { document: creators } = useCollection(
        "creators"
    );
    const actualCreator = creators.filter(creator => creator?.userId == user?.uid);

    useEffect(() => {
        setTimeout(() => {
            setShowLoading(false);
        }, 3000)
    }, [])

    useEffect(() => {
        if(actualCreator.length) {
            navigate("/")
        }
    }, [actualCreator])
    return (
        <>
            <Navbar />

            {
                user?.emailVerified ? showLoading ? <Progress /> : <>
                    {
                        !showForm && !showExperienceDetails && <AskingExp setExperience={setExperience} setShowForm={setShowForm} setShowExperienceDetails={setShowExperienceDetails} />
                    }
                    {
                        !experience && showForm && <NoExpDetails />
                    }

                    {
                        experience && showExperienceDetails && <ExpDetails setExperience={setExperience} setShowForm={setShowForm} />
                    }

                </> : <FullScreenMessage>
                    <Container>
                        <h2>We send you a varification link towards your email.Please verify to go Ahead.</h2>
                    </Container>
                </FullScreenMessage>
            }

        </>
    )
}
