import React from 'react'
import creatorProfile from "../../../assets/images/creatorProfile.png"

import { ProfileDetails, ProfileSidebar, SidebarLogout } from '../../../styles/Profile.styled'
import { Button } from '../../../styles/Common.styled'
import { FaSignOutAlt } from "react-icons/fa";
import { useLogout } from '../../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';

export default function CreatorSidebar({setShowInfo, creator}) {
    const {LogOut} = useLogout();
    const navigate = useNavigate("");

    const handleLogout = () => {
        LogOut();
        navigate("/");
    }
    return (
        <ProfileSidebar>
            <div>
                <ProfileDetails>
                    <div>
                        <img src={creatorProfile} alt="creatorProfile" />
                    </div>
                    <div>
                        <h2>{creator?.name}</h2>
                        <p>Update your personal Details</p>
                    </div>
                </ProfileDetails>
                <Button onClick={() => setShowInfo(true)}>Edit Profile</Button>
            </div>
            <SidebarLogout onClick={handleLogout}>
                <FaSignOutAlt />
                <p>Logout</p>
            </SidebarLogout>
        </ProfileSidebar>
    )
}
