import styled from "styled-components"

export const NoExpSection = styled.section`
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ExpSection = styled.section`
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const NoExpDetail = styled.div`
    h2 {
        font-size: 3.2rem;
        margin-bottom: 2rem;
    }
    
    p {
        font-size: 1.8rem;
    }
`

export const NoExpForm = styled.div`
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    padding: 3rem;
    border-radius: .8rem;
`

// Asking question form styling


export const AskingQuestions = styled.div`
    max-width: 1000px;
    min-height: 60vh;
    border-radius: .8rem;
    margin: 0 auto;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 0px 30px 5px rgba(0,0,0,.1);

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
        text-align:center;
        margin: 3rem;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
    }
`

export const AskingLeft = styled.div`
    background: lightblue;
    background-image: url(${({ bg }) => bg});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    h2 {
        font-size: 2.2rem;
    }
    
    p {
        font-size: 1.6rem;
        text-align: center;
    }

    button {
        display: block;
        border: none;
        padding: 2rem 3rem;
        width: 100%;
        background: lightblue;
        cursor: pointer;
        :not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 2rem;
    }
`

export const AskingRight = styled.div`
    background: #fff;
    width: 75%;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    div {
        * {
            margin: .2rem 0;
        }

        h2 {
            font-size: 2.2rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.6rem;
            padding: 2rem;
        }

        img {
            width: 100%;
        }

        button {
            display: block;
            border: none;
            padding: 2rem 3rem;
            font-size: 1.8rem;
            text-transform: uppercase;
            width: 100%;
            background: lightblue;
            border-radius: .4rem;
            cursor: pointer;
            :not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        width: 100%;
    }

`
