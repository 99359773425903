import styled from "styled-components";

export const JobSection = styled.section`
    background:  ${({ theme }) => theme.color.bg};
    min-height: 85vh;
`

export const JobCard = styled.div`
    margin: 2rem 0;
    padding: 2rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: 2.2rem;
        margin-bottom: 2rem;
    }

    p {
        color: rgba(116, 132, 153, 1);
        font-size: 1.8rem;
    }
`

export const IndividualJobSection = styled.section`
    background:  ${({ theme }) => theme.color.bg};
    min-height: 85vh;
`

export const JobImageSection = styled.section`
    background: url('https://c1.wallpaperflare.com/preview/150/478/521/boardroom-city-office-business-room-conference.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30vh;
`

export const JobDetailsSection = styled.section`
    max-width: 100rem;
    margin: 0 auto;
    background: rgba(255,255,255,.8);
    padding: 3rem;
    transform: translateY(-15rem);

    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
    border-radius: .8rem;

    h2 {
        font-size: 3.2rem;
    }

    @media (max-width: 1001px) {
        margin: 0 2rem;
    }
`


export const JobDetail = styled.section`
    p:nth-child(odd) {
        color: #000;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    p:nth-child(even) {
        color: rgba(116, 132, 153, 1);
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }
`

export const JobApplying = styled.div`
    max-width: 1000px;
    padding: 3rem;
    margin: 5rem auto;

    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    border-radius: .8rem;

    @media (max-width: 1001px) {
        margin: 5rem;
    }
`