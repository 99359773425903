import React from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useCollection } from '../../../hooks/useCollection';
import { Container } from '../../../styles/Common.styled'
import { ProjectDetailSection } from '../../../styles/ProjectDetails.styled'
import AssessmentSubmittingForm from './AssessmentSubmittingForm'

export default function AssessmentDetails() {
    const { user } = useAuthContext();

    const { document: creators } = useCollection(
        "creators",
        user?.uid && ["userId", "==", user?.uid]
    )

    const giveAssessment = creators?.filter(creator => creator?.type === "give_assessment");
    
    return (
        <Container>
            <ProjectDetailSection>
                <h2>{giveAssessment[0]?.assessment?.projectName}</h2>
                <p>{giveAssessment[0]?.assessment?.projectDescription}</p>
                <p>Design: <a href={giveAssessment[0]?.assessment?.figmaLink} target="_blank">{giveAssessment[0]?.assessment?.figmaLink}</a></p>
                <AssessmentSubmittingForm giveAssessment={giveAssessment[0]} />
            </ProjectDetailSection>
        </Container>
    )
}
