import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { AdminOptions, ClientDetails, JobDetailsSection } from '../../../styles/SuperAdmin.styled'

export default function AllSelectedCreatorDetails({setJobId}) {
  const { isLoading, document } = useCollection("selectedCreators")
  return (
    <JobDetailsSection>
      <AdminOptions>
        <p style={{ borderBottom: `${"2px solid #006AC2"}`, color: `${"#006AC2"}` }}>Selected Creators</p>
      </AdminOptions>

      {
        document && document.map(doc => (
          // <>
          // {console.log("doc", doc.id)}
          // </>
          <ClientDetails key={doc?.id}>
            <p>{doc?.name}</p>
            <button onClick={() => setJobId(doc.id)} >view</button>
          </ClientDetails>
        ))
      }
    </JobDetailsSection>
  )
}
