import React from 'react'
import { ProfileContent, ProfileProject } from '../../../styles/Profile.styled'
import CreatorInfo from './CreatorInfo'
import CreatorProject from './CreatorProject'
import CreatorRules from './CreatorRules'


export default function CreatorDetails({setCreatorDetail, creator,showInfo,  setAlert , alert, creatorDetail }) {
    return (
        <ProfileContent>
            <CreatorRules />
            <CreatorProject />
            {
                showInfo && <CreatorInfo setCreatorDetail={setCreatorDetail} creator={creator}  setAlert = {setAlert} alert = {alert} creatorDetail={creatorDetail}/>
            }
            
        </ProfileContent>
    )
}
