import React, {useState} from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useCollection } from '../../../hooks/useCollection'
import { Container } from '../../../styles/Common.styled'
import { ProfileDetailSection, ProfileSection } from '../../../styles/Profile.styled'
import CreatorDetails from './CreatorDetails'
import CreatorSidebar from './CreatorSidebar'

export default function CreatorProfile() {
  let [showInfo, setShowInfo] = useState(false);
  let [creatorDetail, setCreatorDetail] = useState("");
  let [alert, setAlert] = useState("");
  const { user } = useAuthContext();
  const { document: creators } = useCollection(
    "creators"
  );
  const actualCreator = creators.filter(creator => creator?.userId == user?.uid);
  return (
    <ProfileSection>
      <Container>
        <ProfileDetailSection>
          <CreatorSidebar setShowInfo={setShowInfo} creator = {actualCreator[0]} />
         <CreatorDetails setCreatorDetail={setCreatorDetail} creator = {actualCreator[0]} showInfo={showInfo} setAlert = {setAlert} alert = {alert} creatorDetail={creatorDetail} />
          
        </ProfileDetailSection>
      </Container>
    </ProfileSection>
  )
}
