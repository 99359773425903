import React from 'react'
import SuperAdminCards from '../../../organism/SuperAdminCards/SuperAdminCards'
import { MainData } from '../../../styles/SuperAdmin.styled'
import AppliedJobDataDetails from './AppliedJobDataDetails'

export default function AppliedJobData({ setUserProject }) {
  return (
    <MainData>
      <SuperAdminCards />
      <AppliedJobDataDetails setUserProject={setUserProject} />
    </MainData>
  )
}
